<template>
  <div class="home warpper-content-box">
    <el-row :gutter="50" style="width: 100%; height: 100%">
      <el-col :span="5" style="height: 100%">
        <div style="margin: 20px 0; text-align: center">
          <el-switch v-model="value" active-text="图纸" inactive-text="地图" />
        </div>
        <div
          v-if="permissions.indexOf(0) > -1"
          style="margin: 20px 0; text-align: center"
        >
          <el-button
            style="width: 120px; color: #999"
            @click="dialog3 = true"
            type="text"
          >
            <img
              width="20"
              style="margin-right: 6px"
              src="../assets/image/shangchuan.png"
              alt=""
              srcset=""
            />
            上 传 图 纸
          </el-button>
        </div>
        <div style="margin: 20px 0; text-align: center">
          <el-button style="width: 120px; color: #999" type="text" @click="setCenter">
            <img
              width="20"
              style="margin-right: 6px"
              src="../assets/image/dingwei.png"
              alt=""
              srcset=""
            />
            设置中心点
          </el-button>
        </div>

        <div
          style="margin: 20px 0; text-align: center"
          v-if="permissions.indexOf(0) > -1"
        >
          <div class="uploadbtn">
            <el-button style="width: 120px; color: #999" type="text"
              ><el-icon style="font-size: 22px"><UploadFilled /></el-icon
              >&nbsp;批量上传设备
            </el-button>

            <input
              @change="updateElecl"
              type="file"
              ref="excelFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="19" style="">
        <div
          style="
            width: 100%;
            height: calc(100vh - 140px);
            margin: 20px;
            border-radius: 5px;
          "
          v-show="!modeBoom"
          id="mapDiv"
        ></div>
        <div
          v-show="modeBoom"
          style="
            width: 100%;
            height: calc(100vh - 140px);
            overflow-y: auto;
            margin: 20px;
            border-radius: 5px;
          "
        >
          <img style="width: 100%" :src="drawings" alt="" />
        </div>
      </el-col>
    </el-row>

    <el-dialog v-model="dialog1" title="设置中心点" width="500px">
      <el-form :model="dialog1Form" label-width="80px">
        <el-form-item label="经度">
          <el-input
            type="text"
            v-model="dialog1Form[0]"
            placeholder="请输入经度"
          ></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input
            type="text"
            v-model="dialog1Form[1]"
            placeholder="请输入纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="地图层级">
          <el-input
            type="number"
            v-model="dialog1Form[2]"
            placeholder="请输入缩放等级"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog1 = false" type="">取消</el-button>
        <el-button type="primary" @click="submitData">提交</el-button>
      </template>
    </el-dialog>

    <el-dialog
      v-model="dialog2"
      :title="'编辑设备-' + (editDevice.name ? editDevice.name : editDevice.id)"
      width="500px"
    >
      <el-form :model="dialog2Form">
        <el-form-item label="经度">
          <el-input v-model="dialog1Form2[1]" placeholder="请输入经度"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input v-model="dialog1Form2[2]" placeholder="请输入纬度"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog2 = false" type="">取消</el-button>
        <el-button type="primary" @click="submitData2">提交</el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="dialog3" title="上传图纸" width="500px">
      <div class="file-btn">
        <input
          type="file"
          ref="drawingFile"
          @change="updataFile($event)"
          accept="image/*"
        />
        <img ref="previewImg" src="" alt="" />
        <el-icon class="avatar-uploader-icon">
          <Plus />
        </el-icon>
      </div>

      <template #footer>
        <el-button @click="dialog3 = false" type="">取消</el-button>
        <el-button type="primary" @click="submitMapfile">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  setMapCentral,
  uploadMapfile,
  reloadMapfile,
  deviceList,
  getMapfileMode,
  setMapfileMode,
  setDevicePosition,
} from "../utils/api.js";
import xlsx from "xlsx";
import { Plus, UploadFilled } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import CoordinateUtil from "../utils/coordinates";
export default {
  components: { Plus, UploadFilled },
  props: {},
  data() {
    return {
      permissions: [],
      value: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog1Form: ["113.53", "22.65", 10],
      dialog1Form2: ["", "", ""],
      pageNum: 0,
      modeBoom: true, //true地图  false图纸
      drawings: "",
      bdmap: null,
      editDevice: {},
    };
  },
  watch: {
    value(newV) {
      this.modeBoom = newV;
      setMapfileMode({ params: [newV] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });
          getMapfileMode({
            params: [],
          }).then((res) => {
            this.value = res.result[0];
            this.modeBoom = res.result[0];
            this.dialog1Form = [res.result[1], res.result[2], res.result[3]];
          });
        }
      });
    },
  },
  computed: {},
  methods: {
    updateElecl(event) {
      //批量上传设备
      if (!event.currentTarget.files.length) {
        return;
      }
      const that = this;
      // 拿取文件对象
      var f = event.currentTarget.files[0];
      // 用FileReader来读取
      var reader = new FileReader();
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var wb; // 读取完成的数据
        var outdata; // 你需要的数据
        var reader = new FileReader();
        reader.onload = function (e) {
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // 接下来就是xlsx了，具体可看api
          wb = xlsx.read(binary, {
            type: "binary",
          });
          outdata = xlsx.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          let paramsData = outdata.map((m) => {
            return [String(m["设备id"]), String(m["经度"]), String(m["纬度"])];
          });
          setDevicePosition({
            params: [...paramsData],
          }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "success",
                message: "设置成功！",
                customClass: "mzindex",
              });
              that.dialog2 = false;
              that.getDeviceList(); //刷新数据
            }
            this.$refs.excelFile.value = ""; //上传后清空值
          });
        };
        reader.readAsArrayBuffer(f);
      };
      reader.readAsBinaryString(f);
    },
    submitData2() {
      let that = this;
      setDevicePosition({
        params: [
          [
            this.editDevice.id,
            String(this.dialog1Form2[1]),
            String(this.dialog1Form2[2]),
          ],
        ],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });
          that.dialog2 = false;
          that.getDeviceList(); //刷新数据
        }
      });
    },
    setCenter() {
      this.dialog1 = true;
    },
    submitData() {
      let that = this;
      this.dialog1Form[2] = Number(this.dialog1Form[2]);
      setMapCentral({
        params: this.dialog1Form,
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });
          that.dialog1 = false;
        }
      });
      this.setCenreload();
    },
    setCenreload() {
      this.dialog1 = false;
      this.$nextTick(function () {
        this.bdmap.centerAndZoom(
          new BMapGL.Point(
            this.dialog1Form[0] ? this.dialog1Form[0] : "113.53",
            this.dialog1Form[1] ? this.dialog1Form[1] : "22.65"
          ),
          this.dialog1Form[2]
        );
      });
    },
    parseFileToBase64(file) {},
    submitMapfile() {
      var reader = new FileReader();
      // 将文件加载进入
      let that = this;
      reader.readAsDataURL(this.$refs.drawingFile.files[0]);
      reader.onload = function (e) {
        uploadMapfile({
          params: [this.result],
        }).then((res) => {
          if (!res.error) {
            ElMessage({
              type: "success",
              message: "上传成功！",
              customClass: "mzindex",
            });
            that.dialog3 = false;
          }
        });
      };
    },
    updataFile(obj) {
      let that = this;
      var file = this.$refs.drawingFile.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //成功读取文件
        that.$refs.previewImg.src = e.target.result;
      };
      //
    },
    getDeviceList() {
      deviceList({ params: [-1] }).then((res) => {
        if (!res.error) {
          this.devicelist = res.result[0];
          this.total = res.result[1];
          this.pageSize = res.result[2];
          let newVdataArr = this.devicelist.map((m) => {
            return { lng: m[4], lat: m[5], status: m[0], id: m[1], name: m[2] };
          });
          this.markerPoint(newVdataArr);
        }
      });
    },
    markerPoint(site) {
      var siteForB = [];
      var total = 0; //总记录数
      var groupCount = 0; //每次转十条
      if (site.length % 10 > 0) {
        groupCount = site.length / 10 + 1;
      } else {
        groupCount = site.length / 10;
      }
      new Promise((resolve, reject) => {
        for (var i = 0; i < groupCount; i++) {
          //外层循环，有多少组十条
          var pos = new Array();
          for (var j = 0; j < 10; j++) {
            //内层循环，每组十条
            if (total < site.length) {
              //不超过总记录数结束
              var point = new BMapGL.Point(site[i * 10 + j].lng, site[i * 10 + j].lat);
              pos.push(point);
            }
            total++;
          }

          var convertor = new BMapGL.Convertor();
          convertor.translate(pos, 1, 5, function (data) {
            if (data.status === 0) {
              siteForB = siteForB.concat(JSON.parse(JSON.stringify(data.points)));
            }
          });
        }
        resolve(siteForB);
      }).then((res) => {
        let that = this;
        setTimeout(function () {
          site.forEach((item, i) => {
            // 创建小车图标
            var myIcon = null;
            if (item.status) {
              myIcon = new BMapGL.Icon(
                require("../assets/image/online.png"),
                new BMapGL.Size(27, 27)
              );
            } else {
              myIcon = new BMapGL.Icon(
                require("../assets/image/offline.png"),
                new BMapGL.Size(27, 27)
              );
            }
            setTimeout(function () {
              var marker = new BMapGL.Marker(
                new BMapGL.Point(siteForB[i].lng, siteForB[i].lat),
                {
                  icon: myIcon,
                }
              );
              marker.addEventListener("click", function (e) {
                that.dialog2 = true;
                that.editDevice = item;
                that.dialog1Form2[1] = item.lng;
                that.dialog1Form2[2] = item.lat;
              });
              that.bdmap.addOverlay(marker);
            }, 1);
          });
        }, 300);
      });
    },
  },
  created() {
    if (window.sessionStorage.getItem("userRole")) {
      this.permissions = JSON.parse(window.sessionStorage.getItem("userRole"));
    }
    reloadMapfile({ params: [] }).then((res) => {
      this.drawings = res.result[0];
    });
  },
  mounted() {
    getMapfileMode({
      params: [],
    }).then((res) => {
      this.value = res.result[0];
      this.modeBoom = res.result[0];
      let coordinateTransformation = CoordinateUtil.getWgs84xy(
        res.result[1],
        res.result[2]
      );
      this.dialog1Form = [
        String(coordinateTransformation.lng),
        String(coordinateTransformation.lat),
        res.result[3],
      ];
      this.$forceUpdate();
      this.$nextTick(function () {
        var zoom = this.dialog1Form[3] ? this.dialog1Form[3] : 10;
        this.bdmap = new BMapGL.Map("mapDiv"); // 创建Map实例
        this.bdmap.centerAndZoom(
          new BMapGL.Point(
            res.result[1] ? res.result[1] : "113.53",
            res.result[2] ? res.result[2] : "22.65"
          ),
          zoom
        ); // 初始化地图,设置中心点坐标和地图级别

        this.bdmap.enableScrollWheelZoom(true);
      });
    });
    this.getDeviceList();
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.avatar-uploader .avatar {
  width: 158px;
  height: 158px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #ccc;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: translate(-50%, -50%);
}

.avatar-uploader .el-upload:hover {
  border: 1px dashed #ccc;
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 158px;
  height: 158px;
  text-align: center;
}
.file-btn {
  width: 160px;
  height: 160px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  & input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  & img {
    min-width: 60%;
    min-height: 60%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: #fff;
  }
}
.uploadbtn {
  width: 120px;
  height: 35px;
  margin: 0 auto;
  position: relative;

  & > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
  }
}
</style>
