import Recorder from "./recorder"
export default class Record {
    constructor() {
        this.volumes = Recorder.volumes
    }
    startRadio(param) {
        // console.log("执行startRadio函数");
        let self = this;
        try {
            Recorder.get(rec => {
                self.recorder = rec;
                self.recorder.readioStart(param);
            })
        } catch (error) {
            param.error("record failed" + error)
        }
    }
    stopRecord() {
        //console.log("stop record new.");
        let self = this;
        try {
            self.recorder.stop();
            //console.log("stop successfully.");
        } catch (e) {
            //console.log("stop record failed" + e);
        }
    }
    endstop() {
        let self = this;
        try {
            self.recorder.stop();
        } catch (e) {

        }
    }
    getErrorstatus() {
        let result = this.recorder.websocketonerror();
        //    console.log(result,"--result--");
        return result;
    }
    stopWebsocket() {
        if (Recorder.webscoke) {
            Recorder.webscoke.close()
        }
        if (Recorder.time) {
            clearInterval(Recorder.time)
        }
    }
}
