<template>
  <div class="warpper-content-box">
    <el-row :gutter="28" style="width:90%;margin:20px 5%">
      <el-col :span="12">
        <div class="renwu-box">
          <p>
            <span>任务栏 <img style="float:right" src="../assets/image/renwuIcon.png" alt="" srcset="" /></span> <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
          </p>
          <el-row :gutter="20" style="margin:20px;height:calc(100vh - 260px)">
            <el-col :span="4">
              <div class="operation-btn" v-if="permissions.indexOf(0) > -1">
                <div style="margin-top:0">
                  <el-button @click="dialog1 = true" type="text" style="color:#999">
                    <img width="16" style="margin-right:2px" src="../assets/image/updateMuise.png" alt="" srcset="" />上传音乐
                  </el-button>
                </div>
                <div>
                  <el-button @click="dialog2 = true" type="text" style="color:#999">
                    <img width="16" style="margin-right:2px" src="../assets/image/updateFile.png" alt="" srcset="" />上传文本
                  </el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="20">
              <el-table style="margin-top:20px" :data="tableData" v-loading="tabLoad">
                <el-table-column v-for="(item, index) in colData" :key="`col_${index}`" :prop="item.prop" :label="item.label" :width="index == 0 ? '150' : ''">
                  <template #default="scope">
                    <span v-if="index == 0">{{ scope.row[2] }}</span>
                    <span v-else-if="index == 1">{{ scope.row[3] }}</span>
                    <span v-else-if="index == 2">
                      <audio :src="`${baseUrl3}/chat/play_test_file?schema=play&path=${scope.row[1]}&token=${token}`" :class="'myaudio'+scope.$index"></audio>
                      <span style="cursor: pointer" @click="pauseMusic(scope.$index,'myaudio'+scope.$index)">
                        {{item[5] === true || item[5] === undefined ? "播放" : "停止"}}</span>
                    </span>
                    <span v-else-if="index == 3">{{
                      scope.row[4] == 0 ? "未审核" : scope.row[4] == -1 ? "不通过" : "通过"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="100px">
                  <template #default="scope">
                    <el-popconfirm v-if="permissions.indexOf(1) > -1" confirm-button-text="通过" cancel-button-text="不通过" title="审核当前文件" @confirm="auditFile(scope.row)" @cancel="cancelEvent(scope.row)">
                      <template #reference>
                        <el-button type="text">审核</el-button>
                      </template>
                    </el-popconfirm>
                    <el-button type="text" @click="deleteFile(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="renwu-box">
          <p>
            <span>音乐曲库 <img style="float:right" src="../assets/image/renwuIcon.png" alt="" srcset="" /></span> <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
          </p>
          <el-row :gutter="20" style="height:calc(100vh - 240px)">
            <el-col :span="4">
              <div class="operation">
                <div v-if="permissions.indexOf(0) > -1">
                  <el-button @click="folders = true" style="color:#999" type="text">
                    <img width="18" style="margin-right:4px" src="../assets/image/addFile.png" alt="" srcset="">
                    添加文件夹
                  </el-button>
                </div>
                <div v-if="permissions.indexOf(0) > -1">
                  <el-button @click="equipmentSync" type="text" style="color:#999">
                    <img width="18" style="margin-right:4px" src="../assets/image/upload.png" alt="" srcset="" />
                    设备同步
                  </el-button>
                </div>
                <div v-if="permissions.indexOf(0) > -1">
                  <el-button @click="editFolder" type="text" style="color:#999">
                    <img width="18" style="margin-right:4px" src="../assets/image/upload.png" alt="" srcset="" />
                    编辑文件夹
                  </el-button>
                </div>
                <div v-if="permissions.indexOf(0) > -1">
                  <el-button @click="deleteFolder" type="text" style="color:#999">
                    <img width="18" style="margin-right:4px" src="../assets/image/upload.png" alt="" srcset="" />
                    删除文件夹
                  </el-button>
                </div>
                <div v-if="updateDom" class="update-jindu">
                  <div :style="{'width':`${percent2}%`}"></div>
                </div>
              </div>
            </el-col>
            <el-col :span="20" style="height:100%">
              <div style="padding:20px;background:#fff;margin: 40px 20px;height:calc(100% - 120px);overflow-y:auto">
                <el-collapse v-loading="songLoading" v-if="foldersTree.length > 0" v-model="activeNames" accordion>
                  <el-collapse-item :name="index + 1" v-for="(item, index) in foldersTree" :key="index">
                    <template #title>
                      <span @click.stop="checkRow(item)" :class="cheNum.indexOf(item.index) > -1 ? 'checked' : 'checkout'"></span>
                      {{ item.name }}
                    </template>
                    <ul v-if="item.children">
                      <li style="text-indent: 1em; height: 35px; line-height: 35px" v-for="(item2, index2) in item.children" :key="index2">
                        {{ item2.name }}
                        <el-button style="float: right;margin-left:20px" @click="delSong(item2)" type="text">删除</el-button>
                        <el-button style="float: right" type="text" @click="downloadFile(item2)">下载</el-button>
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
                <el-empty v-else description="暂无歌单数据！"></el-empty>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-dialog width="500px" v-model="dialog1" title="上传音乐">
      <input class="song-file" @change="updataFile($event)" type="file" ref="file1" />
      <div class="song-box">
        <label for="">选择新增文件夹：</label>
        <el-tree ref="foldersTree" node-key="index" :check-strictly="true" @check="changetree" @check-change="handleNodeClick" :props="props" :data="foldersTree2" show-checkbox />
      </div>
      <template #footer>
        <el-button @click="dialog1 = false" type="">取消</el-button>
        <el-button @click="submit1" type="primary">提交</el-button>
      </template>
    </el-dialog>
    <el-dialog width="1200px" v-model="dialog2" title="上传文本">
      <!-- <el-input type="text" placeholder="请输入文字" v-model="textData" ref="file2" /> -->
      <el-row :gutter="20">
        <el-col :span="16">
          <p>请选择语音合成模式</p>
          <div class="voice-model">
            <el-row :gutter="0">
              <el-col :span="6">
                <ul class="voice-model-tab">
                  <li v-for="item in textTab" @click="textTabActive = item.id" :class="textTabActive == item.id ? 'textTabActive' : ''" :key="item.id">{{item.tabName}} <span style="float:right">{{textTabMain[item.id].length}}</span></li>
                </ul>
              </el-col>
              <el-col :span="18">
                <el-row :gutter="20" class="voice-model-main">
                  <el-col :span="6" v-for="item in textTabMain[textTabActive]">
                    <div class="voice-model-item" @click="modelActive = item.voice">
                      <p>
                        <img width="20" :src="item.gender == 1 ? man : woman" alt="" srcset=""> {{item.name}}
                      <div class="right" :class="modelActive == item.voice ? 'checked' : 'checkout'"></div>
                      </p>
                      <p>{{item.instructions}}</p>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>

          <p style="margin-top:20px">基础参数</p>
          <div class="voice-parameter">
            <div class="voice-parameter-item">
              <label for="" style="float:left">语速</label>
              <el-slider :min="-500" :max="500" style="width:160px;float:left;margin-left:20px" v-model="value1"></el-slider>
              <el-input v-model="value1" style="width:60px;float:left;margin-left:20px;text-align:center" placeholder=""></el-input>
              <span style="margin-left:10px">正常（范围：-500~500）</span>
            </div>
            <div class="voice-parameter-item">
              <label for="" style="float:left">语调</label>
              <el-slider :min="-500" :max="500" style="width:160px;float:left;margin-left:20px" v-model="value2"></el-slider>
              <el-input v-model="value2" style="width:60px;float:left;margin-left:20px;text-align:center" placeholder=""></el-input>
              <span style="margin-left:10px">正常（范围：-500~500）</span>
            </div>
            <div class="voice-parameter-item">
              <label for="" style="float:left">音量</label>
              <el-slider :min="0" :max="100" style="width:160px;float:left;margin-left:20px" v-model="value3"></el-slider>
              <el-input v-model="value3" style="width:60px;float:left;margin-left:20px;text-align:center" placeholder=""></el-input>
              <span style="margin-left:10px">正常（范围：0~100）</span>
            </div>

            <div class="voice-parameter-item">
              <label for="" style="float:left">采样率</label>

              <el-radio-group style="width:160px;float:left;margin-left:20px" v-model="value4">
                <el-radio-button label="16000" :value="16000"></el-radio-button>
                <el-radio-button label="8000" :value="8000"></el-radio-button>
              </el-radio-group>
            </div>

            <div class="voice-parameter-item">
              <label for="" style="float:left">格式</label>

              <el-radio-group style="width:160px;float:left;margin-left:20px" v-model="value5">
                <el-radio-button label="mp3" value="mp3"></el-radio-button>
                <el-radio-button label="wav" value="wav"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="voice-parameter-text">
            <p>输入文案</p>
            <el-input style="margin-top:10px" show-word-limit :rows="18" type="textarea" v-model="textFont"></el-input>

            <div class="voice-parameter-text-icon">
              <audio :src="srcUrl" autoplay></audio>
              <div class="voice-parameter-text-icon-img" @click="submit2" v-loading="srcUrlBool">
                <img style="width:30px" src="../assets/image/yangshengqi.png" alt="" srcset="" />
              </div>
              <p>点击扬声器按钮开始播放</p>
            </div>

            <div style="margin:20px 0">
              <label for="">标题</label>
              <el-input style="width:200px;margin-left:20px" v-model="textTitle" placeholder="请输入文件标题"></el-input>
            </div>

            <p>文件夹</p>
            <el-tree style="width:100%;height:120px;overflow:auto;margin-top:10px" ref="foldersTree2" node-key="index" :check-strictly="true" @check="changetree2" @check-change="handleNodeClick2" :props="props" :data="foldersTree2" show-checkbox />
          </div>
        </el-col>
      </el-row>
      <template #footer>
        <el-button @click="
            dialog2 = false;
            textData = '';
          " type="">取消</el-button>
        <el-button @click="submit2Che" type="primary">提交</el-button>
      </template>
    </el-dialog>

    <!-- 新增文件夹逻辑 -->
    <el-dialog title="新增文件夹" v-model="folders" width="500px">
      <el-form :model="foldersData">
        <el-form-item label="文件夹名称">
          <el-input v-model="foldersData[0]" placeholder="请输入文件夹名称"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="folders = false" type="">取消</el-button>
        <el-button type="primary" @click="submitFolders">确认</el-button>
      </template>
    </el-dialog>
  </div>

  <div class="load-mask" v-if="progress">
    <div class="load-main">
      <div class="update-jindu">
        <div :style="{'width':`${percent}%`}"></div>
      </div>
      <p>已上传{{percent}}%</p>
    </div>
  </div>

  <!-- 设备同步弹框 -->
  <el-dialog width="450px" title="设备同步" v-model="deviceVar" :before-close="handleClose">
    <ul class="progress-warpper">
      <li v-for="(item,index) in Object.keys(deviceList)">
        <div>{{item}}:</div><div><el-progress style="width:330px" :percentage="deviceList[item]" /></div>
      </li>
    </ul>
  </el-dialog>

  <!-- 编辑文件夹 -->
  <el-dialog width="450px" title="编辑文件夹" v-model="folderDialog" :before-close="folderClose">
    <el-input v-model="folderName" placeholder=""></el-input>
    <div style="text-align:right;margin-top:20px">
      <el-button @click="submitEdit" type="primary">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  ElMessage,
  ElMessageBox,
  ElLoading,
} from "element-plus/lib/components";
import { RefreshRight } from "@element-plus/icons-vue";
import sortTable from "../components/sortTable.vue";
import config from "@/utils/url";
import {
  syncProgress,
  getUpdateStatus,
  uploadText,
  getSongTable,
  folderAdd,
  uploadFileCheck,
  uploadFilePass,
  uploadFileDel,
  devicesSync,
  libraySongDel,
  devicesSyncStatus,
  uploadTextCheck,
  downloadMediaFile,
  folderMod,
  folderDel
} from "../utils/api";
import request from "../utils/request";
import { getCurrentInstance } from "vue";

export default {
  components: { sortTable, RefreshRight },
  props: {},
  data() {
    return {
      folderDialog:false,
      folderName:"",
      deviceVar:false,
      deviceList:{},
      updateDom: false,
      srcUrlBool: false,
      baseUrl2: config.baseUrl,
      baseUrl3: config.baseUrl2,
      modelActive: "aiqi",
      value1: 0,
      value2: 0,
      value3: 50,
      value4: 16000,
      value5: "mp3",
      textFont: "",
      textTitle: "",
      man: require("../assets/image/man.png"),
      woman: require("../assets/image/woman.png"),
      textTabActive: 0,
      textTab: [
        {
          tabName: "通用",
          id: 0,
        },
        {
          tabName: "客服",
          id: 1,
        },
        {
          tabName: "直播",
          id: 2,
        },
        {
          tabName: "童声",
          id: 3,
        },
        {
          tabName: "英文",
          id: 4,
        },
        {
          tabName: "方言",
          id: 5,
        },
        {
          tabName: "多语言",
          id: 6,
        },
      ],
      textTabMain: [
        [
          {
            name: "艾琪",
            voice: "aiqi",
            instructions: "温柔女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾诚",
            voice: "aicheng",
            instructions: "标准男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "艾佳",
            voice: "aijia",
            instructions: "标准女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "思琪",
            voice: "siqi",
            instructions: "温柔女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "思佳",
            voice: "sijia",
            instructions: "标准女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "马树",
            voice: "mashu",
            instructions: "儿童剧男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "悦儿",
            voice: "yuer",
            instructions: "儿童剧女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "若兮",
            voice: "ruoxi",
            instructions: "温柔女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾达",
            voice: "aida",
            instructions: "标准男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "思诚",
            voice: "sicheng",
            instructions: "标准男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "宁儿",
            voice: "ninger",
            instructions: "标准女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "小云",
            voice: "xiaoyun",
            instructions: "标准女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "小刚",
            voice: "xiaogang",
            instructions: "标准男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "瑞琳",
            voice: "ruilin",
            instructions: "标准女声",
            gender: 2, //1是男 2是女
          },
        ],
        [
          {
            name: "思悦",
            voice: "siyue",
            instructions: "温柔女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾雅",
            voice: "aiya",
            instructions: "严厉女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾夏",
            voice: "aixia",
            instructions: "亲和女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾美",
            voice: "aimei",
            instructions: "甜美女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾雨",
            voice: "aiyu",
            instructions: "自然女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾悦",
            voice: "aiyue",
            instructions: "温柔女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾婧",
            voice: "aijing",
            instructions: "严厉女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "小美",
            voice: "xiaomei",
            instructions: "甜美女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾娜",
            voice: "aina",
            instructions: "浙普女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "伊娜",
            voice: "yina",
            instructions: "浙普女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "思婧",
            voice: "sijing",
            instructions: "严厉女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾硕",
            voice: "aishuo",
            instructions: "自然男声",
            gender: 1, //1是男 2是女
          },
        ],
        [
          {
            name: "小仙",
            voice: "xiaoxian",
            instructions: "亲切女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "猫小美",
            voice: "maoxiaomei",
            instructions: "活力女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾飞",
            voice: "aifei",
            instructions: "激昂解说",
            gender: 1, //1是男 2是女
          },
          {
            name: "亚群",
            voice: "yaqun",
            instructions: "卖场广播",
            gender: 1, //1是男 2是女
          },
          {
            name: "巧薇",
            voice: "qiaowei",
            instructions: "卖场广播",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾伦",
            voice: "ailun",
            instructions: "悬疑解说",
            gender: 1, //1是男 2是女
          },
          {
            name: "老铁",
            voice: "laotie",
            instructions: "东北老铁",
            gender: 1, //1是男 2是女
          },
          {
            name: "老妹",
            voice: "laomei",
            instructions: "吆喝女声",
            gender: 2, //1是男 2是女
          },
        ],
        [
          {
            name: "思彤",
            voice: "sitong",
            instructions: "儿童音",
            gender: 1, //1是男 2是女
          },
          {
            name: "小北",
            voice: "xiaobei",
            instructions: "萝莉女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾彤",
            voice: "aitong",
            instructions: "儿童音",
            gender: 1, //1是男 2是女
          },
          {
            name: "艾薇",
            voice: "aiwei",
            instructions: "萝莉女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "艾宝",
            voice: "aibao",
            instructions: "萝莉女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "杰力豆",
            voice: "jielidou",
            instructions: "治愈童声",
            gender: 1, //1是男 2是女
          },
        ],
        [
          {
            name: "Harry",
            voice: "harry",
            instructions: "英音男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "Abby",
            voice: "abby",
            instructions: "美音女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Andy",
            voice: "andy",
            instructions: "美音男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "Eric",
            voice: "eric",
            instructions: "英音男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "Emily",
            voice: "emily",
            instructions: "英音女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Luna",
            voice: "luna",
            instructions: "英音女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Luca",
            voice: "luca",
            instructions: "英音男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "Wendy",
            voice: "wendy",
            instructions: "英音女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "William",
            voice: "william",
            instructions: "英音男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "Olivia",
            voice: "olivia",
            instructions: "英音女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Lydia",
            voice: "lydia",
            instructions: "英中双语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "ava",
            voice: "ava",
            instructions: "美语女声",
            gender: 2, //1是男 2是女
          },
        ],
        [
          {
            name: "姗姗",
            voice: "shanshan",
            instructions: "粤语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "小玥",
            voice: "chuangirl",
            instructions: "四川话女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "青青",
            voice: "qingqing",
            instructions: "中国台湾话女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "翠姐",
            voice: "cuijie",
            instructions: "东北话女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "小泽",
            voice: "xiaoze",
            instructions: "湖南重口音男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "佳佳",
            voice: "jiajia",
            instructions: "粤语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "桃子",
            voice: "taozi",
            instructions: "粤语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "大虎",
            voice: "dahu",
            instructions: "东北话男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "艾侃",
            voice: "aikan",
            instructions: "天津话男声",
            gender: 1, //1是男 2是女
          },
        ],
        [
          {
            name: "智香",
            voice: "tomoka",
            instructions: "日语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "智也",
            voice: "tomoya",
            instructions: "日语男声",
            gender: 1, //1是男 2是女
          },
          {
            name: "Indah",
            voice: "indah",
            instructions: "印尼语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Farah",
            voice: "farah",
            instructions: "马来语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Tala",
            voice: "tala",
            instructions: "菲律宾语女声",
            gender: 2, //1是男 2是女
          },
          {
            name: "Tien",
            voice: "tien",
            instructions: "越南语女声",
            gender: 2, //1是男 2是女
          },
        ],
      ],
      progress: false,
      percent: 0,
      percent2: 0,
      upfileLoading: false,
      activeNames: "1",
      tabLoad: false,
      foldersData: [],
      foldersTree: [],
      foldersTree2: [],
      props: {
        label: "name",
        children: "children",
      },
      dialog1: false,
      dialog2: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      tableData: [],
      colData: [
        // { label: "序号", prop: "index" },
        // { label: "文件类型", prop: "type" },
        { label: "文件名称", prop: "name" },
        { label: "上传人", prop: "uploadPop" },
        { label: "测试", prop: "test" },
        { label: "审核状态", prop: "status" },
      ],
      textData: "",
      folders: false,
      checkIndex: null,
      updatafileData: {},
      musicList: [],
      cheNum: [],
      songLoading: false,

      bostatus: false,
      srcUrl: "",
      interval: null,
      permissions: [],
    };
  },
  watch: {},
  computed: {
    token() {
      return window.localStorage.getItem("token");
    },
  },
  methods: {
    downloadFile(item){
      let systemToken = window.localStorage.getItem("systemToken");
      window.open(window.location.origin + '/chat/get_song_file/'+item.name+'?schema=download&id='+item.index+'&token='+systemToken,+'_blank')
      // request({
      //   url: "/chat/get_song_file/"+item.name+'?schema=download&id='+item.index,
      //   method: "post",
      //   header: {
      //     "Content-Type": "application/json",
      //   },
      // }).then((res) => {
      //   const blob = new Blob([res]);
      //   const url = URL.createObjectURL(blob);
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.download = item.name;
      //   link.click();
      // })
    },
    submitEdit(){
      folderMod({params:[this.cheNum[0],this.folderName]}).then(res=>{
        if (!res.error) {
          ElMessage({
            message: "编辑成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.folderDialog = false;
          this.cheNum=[];
          this.folderName="";
          this.getSongData();
        }
      })
    },
    folderClose(){
      this.folderDialog = false;
      this.folderName = "";
    },
    editFolder(){
      if(this.cheNum.length == 1){
        this.folderName = this.foldersTree.filter(res=>{
          return this.cheNum[0] == res.index;
        })[0].name;
        this.folderDialog = true;
      }
    },
    deleteFolder(){
      let that = this;
      if (this.cheNum.length != 1) {
        ElMessage({
          message: "请先选择文件夹,且只能选中1个！",
          type: "error",
          customClass: "mzindex",
        });
        
        return false;
      }
      folderDel({params:this.cheNum}).then(res=>{
        if (!res.error) {
          ElMessage({
            message: "删除成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.cheNum=[]
          this.getSongData();
        }
      })
    },
    handleClose(){
      if(this.interval){
        clearInterval(this.interval)
        this.interval = null;
      }
      this.deviceVar = false
    },
    pauseMusic(index, classid) {
      let itemData = JSON.parse(JSON.stringify(this.tableData[index]));
      let myAuto = document.getElementsByClassName(classid)[0];
      if (
        this.tableData[index][5] === undefined ||
        this.tableData[index][5] === true
      ) {
        myAuto.play(); //播放
        itemData[5] = false;
        this.tableData[index] = itemData;
      } else {
        myAuto.pause(); //暂停
        itemData[5] = true;
        this.tableData[index] = itemData;
      }
      this.$forceUpdate();
    },
    handleNodeClick(data, checked, node) {
      if (checked) {
        this.$refs.foldersTree.setCheckedKeys([data.index]);
      }
    },

    changetree(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.foldersTree.setCheckedKeys([data.index]);
      }
    },
    handleNodeClick2(data, checked, node) {
      if (checked) {
        this.$refs.foldersTree2.setCheckedKeys([data.index]);
      }
    },
    changetree2(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.foldersTree2.setCheckedKeys([data.index]);
      }
    },
    auditFile(item) {
      uploadFilePass({
        params: [item[0], 1],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            message: "审核通过！",
            type: "success",
            customClass: "mzindex",
          });
          this.dialog1 = false;
          this.getTasksSummary();
          this.getSongData();
        }
      });
    },
    cancelEvent(item) {
      uploadFilePass({
        params: [item[0], "-1"],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            message: "审核不通过！",
            type: "success",
            customClass: "mzindex",
          });
          this.dialog1 = false;
          this.getTasksSummary();
        }
      });
    },
    delSong(item) {
      let item2 = JSON.parse(JSON.stringify(item));
      ElMessageBox.confirm("是否删除当前文件！", "提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          libraySongDel({ params: [item2.index] }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "success",
                message: "删除成功！",
                customClass: "mzindex",
              });
              this.getSongData();
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
            customClass: "mzindex",
          });
        });
    },
    deleteFile(item) {
      ElMessageBox.confirm("是否删除当前文件！", "提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          uploadFileDel({
            params: [item[0]],
          }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "success",
                message: "删除成功！",
                customClass: "mzindex",
              });
              this.getTasksSummary();
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
            customClass: "mzindex",
          });
        });
    },
    submitFolders() {
      folderAdd({ params: [-1, this.foldersData[0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            message: "新增成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.folders = false;
          this.getSongData();
        }
      });
    },
    getTasksSummary() {
      let that = this;
      getUpdateStatus({ params: [0] }).then((res) => {
        if (!res.error) {
          this.tableData = res.result;
          if (this.tabLoad == true) {
            setTimeout(function () {
              that.tabLoad = false;
            }, 500);
          }
        }
      });
    },
    updataFile(event) {
      this.progress = true;
      //上传音乐
      let formFile = new FormData();
      let fileData = event.target.files[0];
      formFile.append("params", fileData);
      // /chat/upload_file
      request({
        url: "/chat/upload_file",
        method: "post",
        header: {
          "Content-Type": "application/json",
        },
        data: formFile,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            this.percent =
              ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)
          }
        },
      })
        .then((res) => {
          if (!res.error) {
            this.updatafileData = event.target.files[0];
            ElMessage({
              message: "上传成功！",
              type: "success",
              customClass: "mzindex",
            });
          }
        })
        .finally(() => {
          this.progress = false;
        });
    },
    submit1() {
      let checkTreeData = JSON.parse(
        JSON.stringify(this.$refs.foldersTree.getCheckedNodes())
      ).map((m) => {
        return m.index;
      });
      checkTreeData =
        checkTreeData == null ||
        checkTreeData == "" ||
        checkTreeData == undefined
          ? []
          : checkTreeData;
      if (!checkTreeData || checkTreeData.length == 0) {
        ElMessage({
          message: "请先选择文件夹",
          type: "error",
          customClass: "mzindex",
        });
        return false;
      }
      uploadFileCheck({
        params: [this.updatafileData.name, checkTreeData[0]],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            message: "提交审核成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.dialog1 = false;
          this.getTasksSummary();
          // this.getSongData();
        }
      });
    },
    submit2() {
      this.srcUrlBool = true;
      // this.srcUrl = `${this.baseUrl2}chat/download_media_file?schema=translate&text=${this.textFont}&format=${this.value5}&sample_rate=${this.value4}&voice=${this.modelActive}&volume=${this.value3}&speech_rate=${this.value1}&pitch_rate=${this.value2}&token=${this.token}`;
      uploadText({
        params: [
          {
            schema: "translate",
            text: this.textFont,
            format: this.value5,
            sample_rate: this.value4,
            voice: this.modelActive,
            volume: this.value3,
            speech_rate: this.value1,
            pitch_rate: this.value2,
          },
        ],
      }).then((res) => {
        let that = this;

        this.srcUrl = `${this.baseUrl2}chat/play_test_file?schema=play&path=${res.result[0]}`;

        let yuyin = setInterval(function () {
          if (this.srcUrl != "") {
            that.srcUrlBool = false;
            clearInterval("yuyin");
          }
        }, 1000);
      });
    },

    submit2Che() {
      if (!this.textFont) {
        this.$message.error("请先填写文案");
        return false;
      }
      if (!this.textTitle) {
        this.$message.error("请先输入标题");
        return false;
      }

      let checkWen = JSON.parse(
        JSON.stringify(this.$refs.foldersTree2.getCheckedNodes())
      );
      if (checkWen.length == 0) {
        this.$message.error("请先选择文件夹");
        return false;
      }
      uploadTextCheck({
        params: [
          {
            text: this.textFont, //非空
            format: this.value5,
            sample_rate: this.value4,
            voice: this.modelActive,
            volume: this.value3,
            speech_rate: this.value1,
            pitch_rate: this.value2,
          },
          this.textTitle + "." + this.value5,
          checkWen[0].index,
        ],
      }).then((res) => {
        if (!res.error) {
          this.dialog2 = false;
          this.getTasksSummary();
        }
      });
    },
    equipmentSync() {
      let that = this;
      if (this.cheNum.length == 0) {
        ElMessage({
          message: "请先选择文件夹！",
          type: "error",
          customClass: "mzindex",
        });
        
        return false;
      }
      devicesSync({ params: [...this.cheNum] }).then((res) => {
        if (!res.error) {
          syncProgress({ params: [] }).then((res) => {
            that.deviceList = res.result[0];
            that.deviceVar = true;
          });
          if (this.interval === null) {
            this.interval = setInterval(function () {
              syncProgress({ params: [] }).then((res) => {
                that.deviceList = res.result[0];
                  that.deviceVar = true;
              });
            },5000);
          }
        }
      });
    },
    getSongData() {
      this.songLoading = true;
      let that = this;
      getSongTable().then((res) => {
        if (!res.error) {
          this.foldersTree = res.result.filter((f) => {
            return f.type == true;
          });
          this.foldersTree2 = res.result
            .filter((f) => {
              return f.type == true;
            })
            .map((m) => {
              return {
                index: m.index,
                name: m.name,
              };
            });

          setTimeout(function () {
            that.songLoading = false;
          }, 100);
        }
      });
    },

    checkRow(item) {
      if (this.cheNum.indexOf(item.index) > -1) {
        let checkNumdata = JSON.parse(JSON.stringify(this.cheNum));
        this.cheNum = checkNumdata.filter((f) => f != item.index);
      } else {
        this.cheNum.push(parseInt(item.index));
      }
    },
  },
  created() {
    this.getTasksSummary();

    this.getSongData();

    if (window.sessionStorage.getItem("userRole")) {
      this.permissions = JSON.parse(window.sessionStorage.getItem("userRole"));
    }
  },
  mounted() {},
  beforeUnmount() {
    this.updateDom = false;
    clearInterval(this.interval);
    this.interval = null;
  },
};
</script>
<style lang="scss" scoped>
.operation {
  padding: 20px;
  & > div {
    margin-top: 20px;
  }
}
.operation-btn {
  padding: 20px 0;
  height: 30px;
  & .operationBtn {
    float: left;
  }
  & > div {
    margin-top: 20px;
  }
}
.file-box {
  width: 88px;
  height: 32px;
  overflow: hidden;
  float: left;
  margin: 0 10px;
  position: relative;

  & input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 88px;
    height: 32px;
    opacity: 0;
  }
}

.song-file {
  margin-bottom: 30px;
}
.song-box {
  & > label {
    line-height: 35px;
  }
}
.checked {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  background-image: url(../assets/image/checked.png);
  background-size: 100% 100%;
  background-position: 0 0;
  margin-right: 20px;
}
.checkout {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  background-image: url(../assets/image/checkout.png);
  background-size: 100% 100%;
  background-position: 0 0;
  margin-right: 20px;
}
.load-mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  // 进度条
  & .load-main {
    width: 160px;
    height: 50px;
    // background: red;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    & .update-jindu {
      width: 160px;
      height: 10px;
      border-radius: 10px;
      background: #ddd;
      & > div {
        height: 10px;
        background: #409eff;
        border-radius: 10px;
      }
    }

    & > p {
      line-height: 20px;
      text-align: center;
      color: #fff;
      margin-top: 10px;
    }
  }
}
.update-jindu {
  width: 80px;
  height: 7px;
  border-radius: 7px;
  background: #ddd;
  & > div {
    height: 7px;
    background: #409eff;
    border-radius: 7px;
  }
}
.voice-model {
  width: 100%;
  height: 386px;
  border: 1px solid #ddd;
  margin-top: 10px;
  & .voice-model-tab {
    width: 100%;
    height: 386px;
    background: #f4f4f4;

    & > li {
      width: calc(100% - 20px);
      height: 50px;
      line-height: 50px;
      text-indent: 2em;
      padding-right: 20px;
    }
  }
  & .voice-model-main {
    padding: 10px;
    & .voice-model-item {
      height: 40px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      & > p:nth-child(1) {
        line-height: 20px;
        & > img {
          float: left;
        }
      }
      & > p:nth-child(2) {
        line-height: 20px;
        margin-top: 5px;
      }
    }
  }
}
.voice-parameter {
  margin-top: 10px;
  & > .voice-parameter-item {
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin-top: 10px;
  }
}
.textTabActive {
  background: #eef7fe;
}
.voice-parameter-text {
  width: 100%;

  & .voice-parameter-text-icon {
    width: 100%;
    text-align: center;

    & .voice-parameter-text-icon-img {
      width: 50px;
      height: 50px;
      border: 1px solid #409eff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto 10px auto;
    }
  }
}
.right {
  float: right;
}

.renwu-box {
  background: #eff2f9;
  & > p:nth-child(1) {
    width: calc(100% - 30px);
    height: 26px;
    padding: 7px 15px;
    background: #3d7fff;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: flex;
      align-items: center;
      & > img {
        margin-left: 6px;
      }
    }
  }
}

.renwu-box {
  background: #eff2f9;
  & > p:nth-child(1) {
    width: calc(100% - 30px);
    height: 26px;
    padding: 7px 15px;
    background: #3d7fff;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: flex;
      align-items: center;
      & > img {
        margin-left: 6px;
      }
    }
  }

  & .greyBg {
    height: 10px;
    background: #eff2f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.progress-warpper{
  & li{
    height:30px;
    line-height:30px;
    display: flex;
    align-items: center;

    &>div:nth-child(1){
      width:60px
    }
    &>div:nth-child(2){
      flex:1
    }
  }
}
</style>
