<template>
  <div class="home warpper-content-box">
    <el-row :gutter="20" style="width: 100%">
      <el-col :span="5">
        <div style="margin: 20px; text-indent: 1em">
          <!-- <el-button @click="addPartition" type="primary">新建分区</el-button> -->
          <el-button @click="addsub" type="text" style="color: #999">
            <svg-icon size="18" style="margin-right: 5px" name="addBtn"></svg-icon>
            添加分区
          </el-button>
          <el-button @click="editPartition" type="text" style="color: #999">
            <svg-icon size="20" style="margin-right: 3px" name="page2Icon2"></svg-icon
            >编辑分区
          </el-button>
        </div>
        <div style="margin: 20px; text-indent: 1em">
          <el-button @click="edlPartition" type="text" style="color: #999">
            <svg-icon size="18" style="margin-right: 3px" name="page2Icon3"></svg-icon
            >删除分区
          </el-button>
        </div>
        <div style="margin: 20px; text-indent: 1em">
          <label for="" style="color: #333">设备设置</label>
          <el-input
            style="width: 100px; margin: 0 6px"
            v-model="deviceName"
            placeholder="设备名称"
          ></el-input>
          <el-button @click="changeName" type="text">修改</el-button>
        </div>

        <div style="margin: 20px; text-indent: 1em">
          <label for="" style="color: #333">首页标题</label>
          <el-input
            style="width: 100px; margin: 0 6px"
            v-model="indexTitle"
            placeholder="首页标题"
          ></el-input>
          <el-button @click="changeTitle" type="text">修改</el-button>
        </div>

        <div style="margin: 20px; text-indent: 1em">
          <label for="" style="color: #333">音量设置</label>
          <el-input
            type="number"
            :max="7"
            :min="-1"
            style="width: 100px; margin: 0 6px"
            v-model.number="volumeVal"
            placeholder="音量设置"
          ></el-input>
          <el-button @click="changeVolume" type="text">修改</el-button>
        </div>
        <p style="font-size: 12px; text-indent: 35px; color: #999">
          当音量设置为-1时，就设置成默认音量。
        </p>

        <div style="margin: 20px; text-indent: 1em">
          <el-button @click="lookDeatil" type="primary">查看详情</el-button>
        </div>
      </el-col>

      <el-col :span="18">
        <el-row
          style="background: #eff2f9; padding: 20px; margin: 35px; border-radius: 15px"
        >
          <el-col :span="6">
            <el-tree
              style="width: 100%; padding-top: 20px; background: #eff2f9"
              default-expand-all
              :highlight-current="true"
              @node-click="handleCheckChange"
              :default-checked-keys="[-1]"
              node-key="index"
              ref="areaTree"
              :data="areaDegree"
              :props="defaultProps"
            >
              <template #default="{ node, data }">
                <div class="custom-tree-node">
                  <img
                    width="24"
                    src="../assets/image/folder.png"
                    alt=""
                    srcset=""
                  />&nbsp;&nbsp;
                  {{ node.label }}
                </div>
              </template>
            </el-tree>
          </el-col>
          <el-col :span="18">
            <equipmentList
              :equipment="devicelist"
              @searchKey="searchKeyword"
              :isCheck="true"
              ref="deviceRef"
              style="margin-top: 30px"
            ></equipmentList>

            <!-- <el-pagination
              :current-page="pageNum + 1"
              :total="total"
              :page-count="pageSize"
              @current-change="handleCurrentChange"
            /> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>

  <el-dialog title="新建分区" :before-close="handleClose" v-model="dialog1" width="800px">
    <el-form :model="dialog1Form" label-width="120px" class="">
      <el-form-item label="分区名称">
        <el-input v-model="dialog1Form[0]" />
      </el-form-item>
      <el-form-item label="未分区设备选择">
        <chooseEquipment v-if="dialog1" ref="chooseId"></chooseEquipment>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog1 = false" type="">取消</el-button>
      <el-button @click="dialog1Submit()" type="primary">提交</el-button>
    </template>
  </el-dialog>

  <el-dialog
    title="编辑分区"
    :before-close="handleClose2"
    v-model="dialog2"
    width="800px"
  >
    <el-form :model="dialog1Form2" label-width="120px" class="">
      <el-form-item label="选择上级">
        <el-tree
          style="width: 100%"
          ref="treeForm"
          class="treeBtn"
          show-checkbox
          node-key="index"
          :check-strictly="true"
          @check="changetree"
          @check-change="handleNodeClick"
          :data="deviceData"
          :default-expanded-keys="[]"
          :props="defaultProps"
        ></el-tree>
      </el-form-item>
      <el-form-item label="分区名称">
        <el-input v-model="dialog1Form2[1]" />
      </el-form-item>
      <el-form-item label="选择设备">
        <chooseEquipment
          v-if="dialog2"
          ref="chooseId2"
          :echoData="shebeiData"
        ></chooseEquipment>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog2 = false" type="">取消</el-button>
      <el-button @click="dialog1Submit2()" type="primary">提交</el-button>
    </template>
  </el-dialog>

  <el-dialog
    title="删除分区"
    :before-close="handleClose3"
    v-model="dialog3"
    width="800px"
  >
    <el-tree
      style="width: 100%"
      ref="treeForm2"
      show-checkbox
      node-key="index"
      :check-strictly="true"
      @check="changetree2"
      @check-change="handleNodeClick2"
      :data="deviceData"
      :props="defaultProps"
    ></el-tree>
    <template #footer>
      <el-button @click="dialog3 = false" type="">取消</el-button>
      <el-button @click="dialog1Submit3()" type="primary">提交</el-button>
    </template>
  </el-dialog>

  <el-dialog
    title="添加分区"
    :before-close="handleClose4"
    v-model="dialog4"
    width="800px"
  >
    <el-form :model="dialog1Form" label-width="120px" class="">
      <el-form-item label="选择分区">
        <el-tree
          style="width: 100%"
          ref="treeForm3"
          show-checkbox
          node-key="index"
          :check-strictly="true"
          @check="changetree3"
          @check-change="handleNodeClick3"
          :data="deviceData"
          :props="defaultProps"
        ></el-tree>
      </el-form-item>
      <el-form-item label="分区名称">
        <el-input v-model="dialog1Form4[0]" />
      </el-form-item>
      <el-form-item label="选择设备">
        <chooseEquipment ref="chooseId3"></chooseEquipment>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog4 = false" type="">取消</el-button>
      <el-button @click="dialog1Submit4()" type="primary">提交</el-button>
    </template>
  </el-dialog>

  <div class="base-msg-box" v-if="baseMsg">
    <div class="base-msg-warpper">
      <p class="base-msg-close">
        <span @click="baseMsg = false">×</span>
      </p>
      <el-row class="base-msg-row" :gutter="60" style="margin:0 0 20px 0">
        <el-col :span="8">
          <div class="base-msg-div">
            <label for="">
              <span style="width:60px">名称：</span><input disabled v-model="baseMsgForm.name" class="grey-input" type="text"/>
            </label>
          </div>
          <div class="base-msg-div">
            <label for="">
              <span style="width:60px">音量：</span><input disabled v-model="baseMsgForm.vol" class="grey-input" type="text"/>
            </label>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="base-msg-div">
            <label for="">
            <span>IMEI号：</span><input v-model="baseMsgForm.imei" disabled class="grey-input" type="text"/>
            </label>
          </div>
          <div class="base-msg-div">
            <label for="">
            <span>播放状态：</span><input v-model="baseMsgForm.state" disabled class="grey-input" type="text"/>
            </label>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="base-msg-div">
            <label for="">
            <span>卡号：</span><input v-model="baseMsgForm.number" disabled class="grey-input" type="text"/>
            </label>
          </div>
          <div class="base-msg-div">
            <label for="">
              <span>剩余内存：</span><input v-model="baseMsgForm.memory" disabled class="grey-input" type="text"/>
              <el-button type="primary" style="margin-left:20px">同步</el-button>
            </label>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="28" style="margin:20px 0">
        <el-col :span="12">
          <div class="renwu-box">
            <p>
              <span>歌曲列表 <img style="float:right" src="../assets/image/renwuIcon.png" alt="" srcset="" /></span> <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
            </p>
            <el-collapse v-if="foldersTree.length > 0" v-model="activeNames" accordion>
                <el-collapse-item :name="index + 1" v-for="(item, index) in foldersTree" :key="index">
                  <template #title>
                    <span @click.stop="checkRow(item)" :class="cheNum.indexOf(item.index) > -1 ? 'checked' : 'checkout'"></span>
                    {{ item.name }}
                  </template>
                  <ul v-if="item.children">
                    <li style="text-indent: 1em; height: 35px; line-height: 35px" v-for="(item2, index2) in item.children" :key="index2">
                      {{ item2.name }}
                      <el-button style="float: right;margin-left:20px" @click="delSong(item2)" type="text">删除</el-button>
                      <el-button style="float: right" type="text" @click="downloadFile(item2)">下载</el-button>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="renwu-box">
            <p>
              <span>任务列表 <img style="float:right" src="../assets/image/renwuIcon.png" alt="" srcset="" /></span> <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
            </p>
            <el-table :data="baseMsgForm.task">
              <el-table-column prop="name" label="任务名称"/>
              <el-table-column prop="type" label="任务类型"/>
              <el-table-column prop="start" label="开始时间"/>
              <el-table-column prop="end" label="结束时间"/>
              <!-- <el-table-column prop="name" label="曲目列表"/> -->
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import chooseEquipment from "../components/chooseEquipment.vue";
import equipmentList from "../components/equipmentList.vue";
import {
  getDeviceInfo,
  deviceList,
  setDeviceName,
  areaDel,
  areaCreate,
  areaMod,
  getAreaDegree,
  getAreaDegreeB2,
  subAreaAdd,
  getDeviceByArea,
  setGainVol,
  setPlatformTitle,
  alldeviceVolume,
  getTaskSong
} from "../utils/api";
export default {
  components: { equipmentList, chooseEquipment },
  props: {},
  data() {
    return {
      checkedId: -1,
      deviceName: "",
      areaDegree: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      col: ["时间", "设备名", "操作类型", "操作人", "内容"],
      defaultProps: {
        label: "name",
        children: "children",
      },
      tableData: [],
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      logForm: [],
      value: false,
      value2: "",
      value3: "",
      dialog1Form: [],
      dialog1Form2: [],
      dialog1Form4: [],
      devicelist: [],
      devicelistAll: [],
      deviceData: [],
      total: 0,
      pageNum: 0,
      pageSize: 0,
      checked1: false,
      checked2: false,
      indexTitle: "",
      shebeiData: [],
      volumeVal: null,
      baseMsg:false,
      baseMsgForm:{},
      foldersTree:[],
      activeNames:""
    };
  },
  watch: {},
  computed: {},
  methods: {
    lookDeatil(){
      let deviceArr = JSON.parse(JSON.stringify(this.$refs.deviceRef.checkDevice));
      if(deviceArr.length == 1){
        getDeviceInfo({
          params: deviceArr,
        }).then((res) => {
          if (!res.error) {
            this.baseMsg = true;
            this.baseMsgForm = res.result[0];
            console.log(res.result);
          }
        });

        getTaskSong({
          params: deviceArr,
        }).then((res) => {
          if (!res.error) {
            console.log(res.result);
            this.foldersTree = res.result;
          }
        });
      }else{
        ElMessage({
          type: "error",
          message: "只能查看一个设备！",
          customClass: "mzindex",
        });
      }
    },
    changeVolume() {
      if (this.volumeVal < -1 && this.volumeVal > 7) {
        ElMessage({
          type: "error",
          message: "请先输入正确的音量值！",
          customClass: "mzindex",
        });
        return false;
      }
      alldeviceVolume({
        params: [this.volumeVal],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });
        }
      });
    },
    searchKeyword(val) {
      if(val){
        this.devicelist = this.devicelist.filter((m) => m[1].indexOf(val) > -1 || m[2].indexOf(val) > -1);
      }else{
        this.devicelist = this.devicelistAll;
      }
    },
    changeTitle() {
      // 悠普仕广播云平台
      setPlatformTitle({
        params: [this.indexTitle],
      }).then((res) => {
        if (!res.error) {
          this.$message.success("设置成功！");
        } else {
          this.$message.error("设置失败！");
        }
      });
    },
    changeName() {
      if (!this.deviceName) {
        ElMessage({
          type: "error",
          message: "请先输入设备名称！",
          customClass: "mzindex",
        });
        return false;
      }
      let deviceL = this.$refs.deviceRef.checkDevice;
      if (!deviceL || deviceL.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }
      setDeviceName({
        params: [deviceL, this.deviceName],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });

          this.defaultProps = [];
          getDeviceByArea({ params: [this.checkedId, -1] }).then((res) => {
            this.devicelist = res.result[0].map((el) => {
              el[5] = false;
              return el;  
            });
            this.total = res.result[1];
            this.pageSize = res.result[2];
            deviceL = [];
            this.$refs.deviceRef.checkDevice = []
          });
        }
      });
    },
    // 单选操作
    handleCheckChange(data, checked) {
      let checkDevice = this.$refs.deviceRef.checkDevice;
      if (checked) {
        this.checkedId = data.index;
        this.$refs.areaTree.setCheckedKeys([data.index]);
        getDeviceByArea({
          params: [this.checkedId, -1],
        }).then((res) => {
          if (!res.error) {
            this.devicelist = res.result[0].map((r) => {
              if (checkDevice.indexOf(r[1]) > -1) {
                r[5] = true;
              } else {
                r[5] = false;
              }
              return r;
            });
            this.total = res.result[1];
            this.pageSize = res.result[2];
          }
        });
      } else {
        if (this.checkedId === data.index) {
          this.$refs.areaTree.setCheckedKeys([data.index]);
        }
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val - 1;
      this.getDeviceList(this.checkedId);
    },

    edlPartition() {
      getAreaDegreeB2({ params: [] }).then((res) => {
        if (!res.error) {
          this.deviceData = res.result;
        }
      });
      this.dialog3 = true;
    },
    handleNodeClick(data, checked, node) {
      if (checked) {
        this.$refs.treeForm.setCheckedKeys([data.index]);
        this.dialog1Form2[1] = data.name;

        getDeviceByArea({ params: [data.index, -1] }).then((res) => {
          if (!res.error) {
            this.$refs.chooseId2.checkDevice = res.result[0].map((m) => m[1]);
            this.$refs.chooseId2.devicelist.map((m) => {
              res.result[0].map((m2) => {
                if (m[1] == m2[1]) {
                  m[6] = true;
                }
              });
            });
            this.shebeiData = res.result[0];
            // this.$refs.chooseId2.devicelist = res.result;
          }
        });
      }
    },

    changetree(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.treeForm.setCheckedKeys([data.index]);
      }
    },

    handleNodeClick2(data, checked, node) {
      if (checked) {
        this.$refs.treeForm2.setCheckedKeys([data.index]);
      }
    },

    changetree2(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.treeForm2.setCheckedKeys([data.index]);
      }
    },

    handleNodeClick3(data, checked, node) {
      if (checked) {
        this.$refs.treeForm3.setCheckedKeys([data.index]);
      }
    },

    changetree3(data, lst) {
      if (lst.checkedKeys.length == 0) {
        //这里的treeForm是你el-tree命名的ref的值
        this.$refs.treeForm3.setCheckedKeys([data.index]);
      }
    },

    getDeviceList() {
      getDeviceByArea({ params: [this.checkedId, -1] }).then((res) => {
        console.log(res.result[0]);
        this.devicelist = res.result[0].map(el=>{
          el[5] = false;
          return el;
        });
        this.devicelistAll = res.result[0];
        this.total = res.result[1];
        this.pageSize = res.result[2];
      });
    },
    addPartition() {
      this.dialog1 = true;
    },
    addsub() {
      getAreaDegreeB2({ params: [] }).then((res) => {
        if (!res.error) {
          this.deviceData = res.result;
        }
      });
      this.dialog4 = true;
    },
    handleClose() {
      this.dialog1 = false;
    },
    handleClose2() {
      this.dialog2 = false;
    },
    handleClose3() {
      this.dialog3 = false;
    },
    handleClose4() {
      this.dialog4 = false;
    },
    dialog1Submit() {
      let deviceid = [];
      let filterData = this.$refs.chooseId.checkDevice;

      if (filterData && filterData.length > 0) {
        deviceid = filterData;
      }
      areaCreate({ params: [this.dialog1Form[0], deviceid] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "上传成功！",
            customClass: "mzindex",
          });

          this.dialog1 = false;
        }
      });
    },

    dialog1Submit2() {
      let areaid = JSON.parse(JSON.stringify(this.$refs.treeForm.getCheckedNodes())).map(
        (m) => {
          return m.index;
        }
      );
      let deviceid2 = this.$refs.chooseId2.checkDevice;
      // this.$refs.chooseId2.devicelist
      //   .filter((f) => {
      //     return f[6] == true;
      //   })
      //   .map((m) => {
      //     return m[1];
      //   });
      if (!areaid || areaid.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择编辑的分区！",
          customClass: "mzindex",
        });
        return false;
      } else {
        this.dialog1Form2[0] = areaid[0];
      }
      this.dialog1Form2[2] = deviceid2;
      areaMod({ params: [...this.dialog1Form2] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "编辑成功！",
            customClass: "mzindex",
          });
          this.dialog1Form2[0] = '';
          this.dialog1Form2[1] = '';
          this.dialog1Form2[2] = [];
          this.$refs.treeForm.setCheckedKeys(false);
          this.dialog2 = false;
          this.getareaData();
        }
      });
    },

    dialog1Submit3() {
      let areaid2 = JSON.parse(
        JSON.stringify(this.$refs.treeForm2.getCheckedNodes())
      ).map((m) => {
        return m.index;
      });

      if (!areaid2 || areaid2.length == 0) {
        ElMessage({
          type: "success",
          message: "请先选择删除的分区！",
          customClass: "mzindex",
        });
        return false;
      }
      areaDel({ params: areaid2 }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "删除成功！",
            customClass: "mzindex",
          });

          this.dialog3 = false;
          this.getareaData();
        }
      });
    },

    dialog1Submit4() {
      let areaid3 = this.$refs.treeForm3.getCheckedNodes().map((m) => {
        return m.index;
      });

      if (!areaid3 || areaid3.length == 0) {
        areaid3 = [-1];
      }

      let deviceid4 = [];
      let filterData4 = this.$refs.chooseId3.checkDevice;
      // this.$refs.chooseId3.devicelist
      //   .filter((f) => {
      //     return f[6] == true;
      //   })
      //   .map((m) => {
      //     return m[1];
      //   });
      if (filterData4 && filterData4.length > 0) {
        deviceid4 = filterData4;
      }
      subAreaAdd({
        params: [areaid3[0], this.dialog1Form4[0], deviceid4],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "成功！",
            customClass: "mzindex",
          });
          this.dialog4 = false;
          this.getareaData();
        }
      });
    },

    editPartition() {
      getAreaDegreeB2({ params: [] }).then((res) => {
        if (!res.error) {
          this.deviceData = res.result;
        }
      });
      this.dialog2 = true;
    },
    getareaData() {
      getAreaDegree({ params: [] }).then((res) => {
        if (!res.error) {
          this.areaDegree = res.result;
        }
      });
    },
  },
  created() {
    this.getareaData();
    this.getDeviceList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.titleTop {
  padding: 20px 0;

  & i {
    width: 3px;
    height: 16px;
    background: #409eff;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }
}

.checked {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  background-image: url(../assets/image/checked.png);
  background-size: 100% 100%;
  background-position: 0 0;
  float: right;
  margin-top: 8px;
  margin-right: 10px;
}
.checkout {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  float: right;
  background-image: url(../assets/image/checkout.png);
  background-size: 100% 100%;
  background-position: 0 0;
  margin-top: 8px;
  margin-right: 10px;
}
.infinite-list {
  width: 100%;
  height: 160px;
  padding: 0;
  margin: 0;
  list-style: none;
  border-top: 1px solid #ddd;
}
.infinite-list-item {
  width: calc(100% - 2px);
  height: 35px;
  border-bottom: 1px solid #ddd;

  & > span:nth-child(1) {
    font-size: 14px;
  }
}
.custom-tree-node {
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}

::v-deep .el-tree-node__content {
  height: 70px;
  line-height: 70px;
}

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: #fff !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.treeBtn /deep/ .el-tree-node__content {
  height: 28px;
  line-height: 28px;
}

.base-msg-box{
  width:100%;
  height:calc(100vh - 145px);
  position:fixed;
  top:145px;
  left:0;
  background-color:#f5f5f5;
}
.base-msg-warpper{
  width:calc(100% - 80px);
  height:calc(100vh - 245px);
  margin:20px;
  padding:20px;
  border-radius:10px;
  background-color:#fff;
}

.renwu-box {
  background: #eff2f9;
  & > p:nth-child(1) {
    width: calc(100% - 30px);
    height: 26px;
    padding: 7px 15px;
    background: #3d7fff;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: flex;
      align-items: center;
      & > img {
        margin-left: 6px;
      }
    }
  }
}

.renwu-box {
  background: #eff2f9;
  & > p:nth-child(1) {
    width: calc(100% - 30px);
    height: 26px;
    padding: 7px 15px;
    background: #3d7fff;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: flex;
      align-items: center;
      & > img {
        margin-left: 6px;
      }
    }
  }
  & .greyBg {
    height: 10px;
    background: #eff2f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.base-msg-row .base-msg-div{
  height:40px;
  & span{
    width:80px;
    line-height:26px;
    display: inline-block;
    color: #8593a2;
  }
  & input{
    min-width:200px;
    height:28px;
    border-radius:24px;
    border:none;
    background-color:#f0f0f0;
  }
}
.base-msg-close{
  text-align:right;
  font-size:28px
}
.grey-input{
  padding:0 10px;
}
</style>
