<template>
  <div class="logs-warpper">
    <div class="logs-search-input">
      <label for=""> 选择类型 </label>
      &nbsp;
      <el-select v-model="searchForm.type" placeholder="请选择查询类型">
        <el-option :value="-1" label="全部"></el-option>
        <el-option :value="0" label="系统日志"></el-option>
        <el-option :value="1" label="操作日志"></el-option>
      </el-select>

      <label for="" style="margin-left: 30px"> 日期 </label>
      &nbsp;
      <el-date-picker
        value-format="YYYY-MM-DD HH:mm:ss"
        v-model="searchForm.date"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>

      <label for="" style="margin-left: 30px"> 字段检索 </label>
      &nbsp;
      <el-input
        style="width: 200px"
        v-model="searchForm.keyword"
        placeholder=""
      ></el-input>

      <el-button style="margin-left: 20px" @click="getLogsList" type="primary"
        >查询</el-button
      >
    </div>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column v-for="(item, index) in logsHead" :label="item">
        <template #default="scope">
          <div v-if="index == 4">
            <template
              v-if="
                (scope.row[index].indexOf('.wav') > -1 ||
                  scope.row[index].indexOf('.mp3') > -1) &&
                scope.row[2] == '开始广播'
              "
            >
              <audio
                :src="`${baseUrl}/chat/play_test_file?schema=play&path=${scope.row[index]}&token=${token}`"
                :class="'myaudio' + scope.$index"
              ></audio>
              <el-icon
                @click="pauseMusic(scope.$index, 'myaudio' + scope.$index)"
                :size="25"
                ><VideoPlay
              /></el-icon>
              <a
                target="_blank"
                :href="`${baseUrl}/chat/play_test_file?schema=play&path=${scope.row[index]}&token=${token}`"
                :download="scope.row[index]"
              >
                <el-icon @click="" style="margin-left: 20px" :size="25"
                  ><Download
                /></el-icon>
              </a>
            </template>
            <template v-else>
              {{ scope.row[index] }}
            </template>
          </div>
          <div v-else>
            {{ scope.row[index] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getLogs } from "../utils/api";
import { VideoPlay, Download } from "@element-plus/icons-vue";
import config from "@/utils/url";
export default {
  components: { VideoPlay, Download },
  props: {},
  data() {
    return {
      baseUrl: config.baseUrl2,
      searchForm: {
        type: -1,
        date: ["", ""],
        keyword: "",
      },
      tableData: [],
      logsHead: ["日期", "设备名称", "操作类型", "操作人", "内容"],
    };
  },
  watch: {},
  computed: {
    token() {
      return window.localStorage.getItem("token");
    },
  },
  methods: {
    pauseMusic(index, classid) {
      let itemData = this.tableData[index];
      let myAuto = document.getElementsByClassName(classid)[0];
      if (this.tableData[index][5] === undefined || this.tableData[index][5] === true) {
        myAuto.play(); //播放
        itemData[5] = false;
      } else {
        myAuto.pause(); //暂停
        itemData[5] = true;
      }
    },
    getLogsList() {
      getLogs({
        params: [
          this.searchForm.type,
          this.searchForm.date[0],
          this.searchForm.date[1],
          this.searchForm.keyword,
        ],
      }).then((res) => {
        this.tableData = res.result;
      });
    },
  },
  created() {
    this.getLogsList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.logs-warpper {
  width: calc(100% - 120px);
  height: calc(100vh - 80px);
  margin-top: 20px;
  padding: 0 60px;
  background: #fff;
}
.logs-search-input {
  width: 100%;
  margin: 40px 0;
}
/deep/ .el-table {
  // border-color: #1a6ac2;
}
/deep/ .el-table th {
  // border-color: #1a6ac2;
  background: #eff2f9;
}
/deep/ .el-table thead tr {
  // border-color: #1a6ac2;
  background: #eff2f9;
}
</style>
