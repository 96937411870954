<template>
  <div class="warpper-content-box">
    <el-row :gutter="50" style="width: 100%">
      <el-col :span="3" class="button-box">
        <div>
          <el-button @click="addTask" style="width: 110px; color: #666" type="text">
            <svg-icon name="page2Icon1" size="28" color="#333" />
            &nbsp;添加任务
          </el-button>
        </div>
        <div>
          <el-button @click="editRow()" style="width: 110px; color: #666" type="text">
            <svg-icon name="page2Icon2" size="24" color="#333" />
            &nbsp;编辑任务
          </el-button>
        </div>
        <div>
          <el-button @click="removeRow()" style="width: 110px; color: #666" type="text">
            <svg-icon name="page2Icon3" size="20" color="#333" />&nbsp;删除任务
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="palyMuse()" style="width: 110px; color: #666" type="text">
            <svg-icon name="page2Icon0" size="24" color="#333" />
            &nbsp;手动播放
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="stopMuse()" style="width: 110px; color: #666" type="text">
            <svg-icon name="page2Icon4" size="28" color="#333" />&nbsp;手动停止
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="setVolume('+')" style="width: 50px" type="text">
            <svg-icon name="page2Icon8" size="22" color="#333" />
          </el-button>
          <el-button @click="setVolume('-')" style="width: 50px" type="text">
            <svg-icon name="page2Icon9" size="22" color="#333" />
          </el-button>
          <p style="color: #666; text-indent: 16px">音量步进值</p>
        </div>
      </el-col>
      <el-col :span="21">
        <sortTable2
          ref="timeTable"
          :check="true"
          style="margin: 20px auto"
          :tableData="tableData"
        ></sortTable2>
      </el-col>
    </el-row>

    <el-dialog
      v-model="taskDialog"
      :before-close="handleClose"
      width="800px"
      title="新增任务"
    >
      <el-form :model="taskForm" label-width="90px">
        <el-form-item label="任务名称">
          <el-input v-model="taskForm[0]" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="音量">
          <el-input-number
            v-model="taskForm[1]"
            placeholder="请输入音量"
            :min="0"
            :max="10"
            controls-position="right"
            size="large"
          />
          <el-checkbox v-model="checked1" style="margin-left:10px" label="默认音量" size="large" />
        </el-form-item>

        <!-- taskForm[2] 模式 -->
        <el-form-item label="类型">
          <el-select v-model="taskForm[3]" placeholder="请选择类型" size="large">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-time-picker
            value-format="HH:mm:ss"
            v-model="taskForm[4]"
            placeholder="开始时间"
          />
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-picker
            value-format="HH:mm:ss"
            v-model="taskForm[5]"
            placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            v-model="taskForm[6]"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            v-model="taskForm[10]"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="星期选择">
          <el-checkbox-group v-model="taskForm[7]">
            <el-checkbox :label="0">星期一</el-checkbox>
            <el-checkbox :label="1">星期二</el-checkbox>
            <el-checkbox :label="2">星期三</el-checkbox>
            <el-checkbox :label="3">星期四</el-checkbox>
            <el-checkbox :label="4">星期五</el-checkbox>
            <el-checkbox :label="5">星期六</el-checkbox>
            <el-checkbox :label="6">星期日</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="设备">
          <chooseEquipment ref="equipment"></chooseEquipment>
        </el-form-item>
        <el-form-item label="曲目">
          <el-tree
            :data="songList"
            show-checkbox
            ref="songTree"
            style="width: 100%"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose">取消</el-button>
        <el-button @click="submitTasks" type="primary">确认</el-button>
      </template>
    </el-dialog>

    <el-dialog
      v-model="taskDialog2"
      :before-close="handleClose2"
      width="800px"
      title="编辑任务"
    >
      <el-form :model="taskForm2" label-width="90px">
        <el-form-item label="任务名称">
          <el-input v-model="taskForm2[1]" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="音量">
          <el-input-number
            v-model="taskForm2[2]"
            placeholder="请输入音量"
            :min="0"
            :max="10"
            controls-position="right"
            size="large"
          />
        </el-form-item>
        <el-form-item label="播放模式">
          <el-select v-model="taskForm2[4]" placeholder="请选择模式" size="large">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- taskForm2[3] 模式 -->
        <el-form-item label="开始时间">
          <el-time-picker
            value-format="hh:mm:ss"
            v-model="taskForm2[5]"
            placeholder="开始时间"
          />
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-picker
            value-format="hh:mm:ss"
            v-model="taskForm2[6]"
            placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            v-model="taskForm2[7]"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            v-model="taskForm2[11]"
            type="date"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="星期选择">
          <el-checkbox-group v-model="taskForm2[8]">
            <el-checkbox :label="0">星期一</el-checkbox>
            <el-checkbox :label="1">星期二</el-checkbox>
            <el-checkbox :label="2">星期三</el-checkbox>
            <el-checkbox :label="3">星期四</el-checkbox>
            <el-checkbox :label="4">星期五</el-checkbox>
            <el-checkbox :label="5">星期六</el-checkbox>
            <el-checkbox :label="6">星期日</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="设备">
          <chooseEquipment :echoData="taskForm2[9]" ref="equipment2"></chooseEquipment>
        </el-form-item>
        <el-form-item label="曲目">
          <el-tree
            :data="songList"
            show-checkbox
            :default-checked-keys="defaultCheck"
            node-key="index"
            ref="songTree2"
            style="width: 100%"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose2">取消</el-button>
        <el-button @click="submitTasks2" type="primary">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import sortTable2 from "../components/sortTable2.vue";
import {
  // getTasksSummary,
  tasksAdd,
  getTasksDetails,
  tasksMod,
  tasksDel,
  tasksPlay,
  tasksPuse,
  tasksStop,
  setTasksVolume,
  tasksSongSelect,
  getSongTable,
} from "../utils/api";
import chooseEquipment from "../components/chooseEquipment.vue";
import { ElMessage, ElMessageBox } from "element-plus/lib/components";
export default {
  components: { sortTable2, chooseEquipment },
  props: {},
  data() {
    return {
      defaultCheck: [],
      defaultProps: {
        label: "name",
        children: "children",
      },
      checked1:true,
      songList: [],
      tableData: [],
      taskDialog: false,
      taskDialog2: false,
      taskForm: ["", 7, 1, "", "", "", [], [], []],
      taskForm2: [0, "", 7, 1, "", "", "", [], [], []],
      options: [
        {
          label: "噪声告警",
          value: 1,
        },
        {
          label: "红外告警",
          value: 2,
        },
      ],
      permissions: [],
      timeInter: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleCurrentChange(row) {
      console.log(row);
    },
    songSelect(a) {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksSongSelect({ params: [checkRow[0][0], a] }).then((res) => {
        let tips = a == -1 ? "上一首" : "下一首";
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `${tips}`,
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    setVolume(a) {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      setTasksVolume({ params: [checkRow[0][0], Number(a + 1)] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `音量${a}1`,
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    pauseMuse() {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksPuse({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "暂停播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    stopMuse() {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksStop({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "停止播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    palyMuse() {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksPlay({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "正在播放",
            customClass: "mzindex",
          });
          // this.getTasksSummary();
          getTasksDetails({ params: [1] }).then((res) => {
            if (!res.error) {
              this.tableData = res.result;
            }
          });
        }
      });
    },
    editRow() {
      let rowData = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      )[0];
      if (!rowData || rowData.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      this.defaultCheck = rowData[0][9];
      this.taskForm2 = [
        rowData[0],
        rowData[1],
        rowData[4],
        0, //类型
        rowData[6],
        rowData[7],
        rowData[8],
        rowData[9],
        rowData[10],
        rowData[11],
        rowData[12],
        rowData[13],
      ];
      this.taskDialog2 = true;
      this.defaultCheck = this.taskForm2[10];
    },
    removeRow() {
      let itemRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!itemRow || itemRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      ElMessageBox.confirm("是否删除当前任务？", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          tasksDel({ params: [itemRow[0][0]] }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "info",
                message: "删除成功！",
                customClass: "mzindex",
              });
              this.getTasksSummary();
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除！",
            customClass: "mzindex",
          });
        });
    },
    handleClose() {
      this.taskDialog = false;
      this.taskForm = ["", 0, 1, "", "", "", "", [], [], []];
    },
    handleClose2() {
      this.$refs.songTree2.setCheckedNodes([]);
      this.taskDialog2 = false;
      this.taskForm2 = ["", 0, 1, "", "", "", [], [], []];
    },
    submitTasks2() {
      let limits = this.$refs.equipment2.checkDevice;
      let musicTree2 = this.$refs.songTree2
        .getCheckedNodes()
        .filter((f) => f.type == false)
        .map((m) => m.index);
      musicTree2 =
        musicTree2 == null || musicTree2 == undefined || musicTree2 == ""
          ? []
          : musicTree2;
      this.taskForm2[3] = 0;
      this.taskForm2[9] = limits;
      this.taskForm2[10] = musicTree2;
      tasksMod({ params: this.taskForm2 }).then((res) => {
        if (!res.error) {
          this.taskDialog2 = false;
          ElMessage({
            message: "编辑成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    submitTasks() {
      let limits = this.$refs.equipment.checkDevice;
      let musicTree = this.$refs.songTree
        .getCheckedNodes()
        .filter((f) => f.type == false)
        .map((m) => m.index);
      musicTree =
        musicTree == null || musicTree == undefined || musicTree == "" ? [] : musicTree;
      // this.taskForm[6] = Number(this.taskForm[6]) / 1000;
      this.taskForm[2] = 0;
      this.taskForm[8] = limits;
      this.taskForm[9] = musicTree;
      console.log(this.checked1);
      if(this.checked1){
        this.taskForm[1] = 10;
      }
      tasksAdd({ params: this.taskForm }).then((res) => {
        if (!res.error) {
          this.taskDialog = false;
          ElMessage({
            message: "新增成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    getTasksSummary() {
      getTasksDetails({ params: [1] }).then((res) => {
        if (!res.error) {
          this.tableData = res.result;
        }
      });

      getSongTable().then((res) => {
        if (!res.error) {
          this.songList = res.result;
        }
      });
    },
    addTask() {
      this.taskDialog = true;
    },
  },
  created() {
    this.getTasksSummary();

    if (window.sessionStorage.getItem("userRole")) {
      this.permissions = JSON.parse(window.sessionStorage.getItem("userRole"));
    }
  },
  mounted() {
    this.timeInter = setInterval(function () {
      getTasksDetails({ params: [1] }).then((res) => {
        if (!res.error) {
          this.tableData = res.result;
        }
      });
    }, 20000);
  },
  unmounted() {
    clearInterval(this.timeInter);
  },
};
</script>
<style lang="scss" scoped>
.button-box {
  & div {
    padding-left: 35px;
    margin: 28px 0;
  }
}
</style>
