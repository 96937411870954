<template>
  <div class="warpper-content-box">
    <el-row :gutter="50" style="width: 100%">
      <el-col :span="5" class="button-box">
        <!-- <p class="titleTop" style="padding-left: 1em"><i></i> 分区搜索</p> -->
        <el-tree
          default-expand-all
          :highlight-current="true"
          @node-click="changetree"
          :default-checked-keys="[-1]"
          node-key="index"
          ref="areaTree"
          style="width: calc(100% - 10px)"
          :data="areaDegree"
          :props="defaultProps"
        >
          <template #default="{ node, data }">
            <div class="custom-tree-node">
              <img
                width="24"
                src="../assets/image/folder.png"
                alt=""
                srcset=""
              />&nbsp;&nbsp;
              {{ node.label }}
            </div>
          </template>
        </el-tree>
      </el-col>
      <el-col
        :span="11"
        style="margin: 28px 0; height: calc(100vh - 120px); background: #eff2f9"
      >
        <equipmentList
          :isCheck="true"
          :equipment.sync="devicelist"
          @changeCheck="changeData"
          @searchKey="searchKeyword"
          ref="shebeiList"
          style="margin-top: 35px"
        ></equipmentList>
        <!-- <ElPagination
          :total="total"
          :current-page="pageNum + 1"
          :page-count="pageSize"
          @current-change="currentChange"
        ></ElPagination> -->
      </el-col>
      <el-col :span="8" style="margin: 18px 0">
        <div class="songList clearfix">
          <div class="renwu-box" v-if="foldersTree.length > 0">
            <p>
              <span
                >音乐曲库
                <img
                  style="float: right"
                  src="../assets/image/renwuIcon3.png"
                  alt=""
                  srcset=""
              /></span>
              <img src="../assets/image/renwuIcon4.png" alt="" srcset="" />
            </p>
            <el-collapse
              style="margin: 20px; height: calc(100% - 50px); overflow-y: auto"
              v-model="activeNames"
              accordion
            >
              <el-collapse-item
                :name="index + 1"
                v-for="(item, index) in foldersTree"
                :key="index"
              >
                <template #title>
                  <p style="text-indent: 1em">{{ item.name }}</p>
                </template>
                <ul v-if="item.children && item.children.length > 0">
                  <li
                    style="
                      text-indent: 1em;
                      height: 30px;
                      line-height: 30px;
                      background: #eff2f9;
                    "
                    v-for="(item2, index2) in item.children"
                    :key="index2"
                  >
                    {{ item2.name }}
                    <el-checkbox
                      v-model="item2.checkVal"
                      @change="changeData"
                      style="
                        float: right;
                        height: 14px;
                        margin-top: 8px;
                        margin-right: 20px;
                        text-indent: 1em;
                      "
                    ></el-checkbox>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </div>
          <el-empty v-else description="暂无歌单数据！"></el-empty>

          <div
            v-if="foldersTree.length > 0 && permissions.indexOf(2) > -1"
            class="operation"
            style="height: 60px"
          >
            <div @click="operation">
              <p>
                <svg-icon name="stop" size="26" color="" />
              </p>
              <p style="margin-top: 5px">停止</p>
            </div>
            <div @click="operation2">
              <p>
                <svg-icon name="bofang" size="26" color="" />
              </p>
              <p style="margin-top: 5px">播放</p>
            </div>
            <div @click="operation3">
              <p>
                <svg-icon name="suspended" size="26" color="" />
              </p>
              <p style="margin-top: 5px">暂停</p>
            </div>
            <div @click="operation4('-')">
              <p>
                <svg-icon name="shang" size="26" color="" />
              </p>
              <p style="margin-top: 5px">上一曲</p>
            </div>
            <div @click="operation4('+')">
              <p>
                <svg-icon name="xia" size="26" color="" />
              </p>
              <p style="margin-top: 5px">下一曲</p>
            </div>

            <div>
              <p class="yinliang clearfix">
                <span @click="operation5('-')">
                  <svg-icon name="yinjian" size="20" color="" />
                </span>
                <span @click="operation5('+')" style="margin-left: 10px">
                  <svg-icon name="yinjia" size="20" color="" />
                </span>
              </p>
              <p style="margin-top: 5px">音量进步值</p>
            </div>
          </div>
        </div>

        <div class="renwu-box">
          <p>
            <span
              >任务栏
              <img
                style="float: right"
                src="../assets/image/renwuIcon.png"
                alt=""
                srcset=""
            /></span>
            <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
          </p>
          <el-table
            class="renwu-tab"
            @select="dialogCheck"
            style="width: 96%"
            :data="renwuData"
            height="300px"
            ref="renwuTab"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="序号" type="index" width="60">
              <!-- <template #default="scope">{{ scope.row[0] }}</template> -->
            </el-table-column>
            <el-table-column label="状态"
              ><template #default="scope">{{ scope.row[1] }}</template></el-table-column
            >
            <el-table-column label="内容"
              ><template #default="scope">{{ scope.row[2] }}</template></el-table-column
            >
            <el-table-column label="进度" width="120"
              ><template #default="scope">
                <span class="jindu" :title="(scope.row[3] / 1) * 100 + '%'"
                  ><i :style="{ width: (scope.row[3] / 1) * 100 + '%' }"></i
                ></span>
                {{ (scope.row[3] / 1) * 100 + "%" }}
              </template></el-table-column
            >
          </el-table>
          <div class="greyBg"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import equipmentList from "../components/equipmentList.vue";
import {
  getDeviceByArea,
  getAreaDegree,
  getSongTable,
  tasksSongSelect,
  setTasksVolume,
  tempTasksPause,
  tasksStop,
  tempTasksStop,
  tempTasksCreate,
  tempTasksPlay,
  getTasksDetails,
  tempTasksGets,
  tempTasksSong,
  tempTasksVolume,
} from "../utils/api";
import { ElMessage } from "element-plus";
export default {
  components: { equipmentList },
  props: {},
  data() {
    return {
      renwuData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      areaTree: [],
      activeNames: [],
      areaDegree: [],
      foldersTree: [],
      devicelist: [],
      devicelistAll: [],
      total: 0,
      pageNum: 0,
      pageSize: 0,
      cheIndex: -1,
      permissions: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    searchKeyword(val) {
      debugger;
      if(val){
        this.devicelist = this.devicelist.filter((m) => m[1].indexOf(val) > -1 || m[2].indexOf(val) > -1);
      }else{
        this.devicelist = this.devicelistAll;
      }
    },
    changeData() {
      this.$refs.renwuTab.clearSelection();
    },
    dialogCheck(selection, row) {
      let selectTab = JSON.parse(JSON.stringify(row));
      this.devicelist = this.devicelist.map((m) => {
        if (selectTab[4].length > 0) {
          if (selectTab[4].indexOf(m[1]) > -1) {
            m[5] = true;
          } else {
            m[5] = false;
          }
        } else {
          m[4] = false;
        }
        return m;
      });

      this.foldersTree = this.foldersTree.map((m) => {
        if (m.children) {
          m.children.map((m2) => {
            if (selectTab[5].length > 0) {
              console.log(m2);
              if (selectTab[5].indexOf(m2.index) > -1) {
                m2.checkVal = true;
              } else {
                m2.checkVal = false;
              }
            } else {
              m2.checkVal = false;
            }
          });
        }
        return m;
      });
      this.$refs.renwuTab.clearSelection();
      this.selectioned = {}; //这里清除选择的记忆；主要是避免取消掉原来选中的还残留原来选中的东西
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return;
      }
      if (row) {
        this.selectioned = row;
        this.$refs.renwuTab.toggleRowSelection(row, true);
      }
    },
    createList() {
      let checkSheBei = this.$refs.shebeiList.equipment;
      let deviceId = checkSheBei
        .filter((f) => f[4] == true)
        .map((m) => {
          return m[3];
        });

      if (deviceId == null || deviceId == "" || deviceId == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }

      let checkIndex = [];
      this.foldersTree.map((m) => {
        if (m.children) {
          m.children.map((m2) => {
            if (m2.checkVal == true) {
              checkIndex.push(m2.index);
            }
          });
        }
      });
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择音乐！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksCreate({
        params: [deviceId, checkIndex],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "新增临时任务成功",
            customClass: "mzindex",
          });
        }
      });
    },
    operation() {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksStop({ params: [checkIndex[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "停止播放",
            customClass: "mzindex",
          });
          this.updataFun();
        }
      });
    },
    operation2() {
      let selectTab = JSON.parse(JSON.stringify(this.$refs.renwuTab.getSelectionRows()));
      if (selectTab.length > 0) {
        this.devicelist = this.devicelist.map((m) => {
          if (selectTab[0][4].length > 0) {
            selectTab[0][4].map((m2) => {
              if (m[3] == m2) {
                m[5] = true;
              }
            });
          }
          return m;
        });

        this.foldersTree = this.foldersTree.map((m) => {
          if (m.children) {
            m.children.map((m2) => {
              selectTab[0][5].map((m3) => {
                if (m2.index == m3) {
                  m2.checkVal = true;
                }
              });
            });
          }
          return m;
        });
        tempTasksPlay({ params: [selectTab[0][4], selectTab[0][5]] }).then((res) => {
          if (!res.error) {
            ElMessage({
              type: "success",
              message: "正在播放！",
              customClass: "mzindex",
            });
            this.renwuData = res.result.map((m) => {
              m[1] = m[1] === -1 ? "暂停" : m[1] === 0 ? "停止" : "播放"; //-1，暂停，0-停止，1-播放
              return m;
            });
          }
        });
        return false;
      }

      let checkIndex = [];
      this.foldersTree.map((m) => {
        if (m.children) {
          m.children.map((m2) => {
            if (m2.checkVal == true) {
              checkIndex.push(m2.index);
            }
          });
        }
      });
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      let checkSheBei = this.$refs.shebeiList.equipment;
      let deviceId = checkSheBei
        .filter((f) => f[5] && f[5] == true)
        .map((m) => {
          return m[1];
        });
      console.log(checkSheBei);
      console.log(deviceId);
      debugger;
      if (deviceId == null || deviceId == "" || deviceId == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksPlay({ params: [deviceId, checkIndex] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "正在播放",
            customClass: "mzindex",
          });
          this.updataFun();
        }
      });
    },
    operation3() {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksPause({ params: [checkIndex[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "暂停播放",
            customClass: "mzindex",
          });
          this.updataFun();
        }
      });
    },
    operation4(a) {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksSong({ params: [checkIndex[0][0], Number(a + 1)] }).then((res) => {
        let tips = a == "-" ? "上一首" : "下一首";
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `${tips}`,
            customClass: "mzindex",
          });
          this.updataFun();
        }
      });
    },
    operation5(a) {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksVolume({ params: [checkIndex[0][0], Number(a + 1)] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `音量${a}1`,
            customClass: "mzindex",
          });
          this.updataFun();
        }
      });
    },
    updataFun() {
      tempTasksGets({ params: [] }).then((res) => {
        if (!res.error) {
          this.renwuData = res.result;
        }
      });
    },
    getDeviceList(a = -1) {
      getDeviceByArea({ params: [a, -1] }).then((res) => {
        //“params”: [ 0 , 0] 所有设备用用-1，未分区0, 其余用上面的分区序号，第二项是分页页面
        this.devicelist = res.result[0].map((el) => {
          el[5] = false;
          return el;
        });
        this.devicelistAll = res.result[0];
        this.total = res.result[1];
        this.pageSize = res.result[2];
      });

      getAreaDegree({ params: [] }).then((res) => {
        if (res.result && res.result.length > 0) {
          this.areaDegree = res.result;
        }
      });

      tempTasksGets({ params: [] }).then((res) => {
        if (!res.error) {
          this.renwuData = res.result;
        }
      });
    },
    searchArea(a = 0) {
      let treeData = this.$refs.shebeiList.equipment.map;
      let checkSheBei = this.$refs.shebeiList.equipment;
      let deviceId = checkSheBei
        .filter((f) => f[4] == true)
        .map((m) => {
          return m[1];
        });
      if (!treeData || treeData.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择分区再查询！",
          customClass: "mzindex",
        });
        return false;
      }
      getDeviceByArea({
        params: [this.cheIndex, -1],
      }).then((res) => {
        //“params”: [ 0 , 0] 所有设备用用-1，未分区0, 其余用上面的分区序号，第二项是分页页面
        if (!res.error) {
          this.devicelist = res.result[0];
          this.devicelistAll = res.result[0];
          this.total = res.result[1];
          this.pageSize = res.result[2];
        }
      });
    },
    currentChange(val) {
      this.pageNum = val - 1;
      this.searchArea();
    },

    changetree(data, lst) {
      let checkDevice = this.$refs.shebeiList.checkDevice;
      this.cheIndex = data.index;
      if (data.index != "" && data.index != null && data.index != undefined) {
        this.pageNum = 0;
        getDeviceByArea({ params: [this.cheIndex, -1] }).then((res) => {
          //“params”: [ 0 , 0] 所有设备用用-1，未分区0, 其余用上面的分区序号，第二项是分页页面
          this.devicelist = res.result[0].map((r) => {
            if (checkDevice.indexOf(r[1]) > -1) {
              r[5] = true;
            } else {
              r[5] = false;
            }
            return r;
          });
          this.total = res.result[1];
          this.pageSize = res.result[2];
        });
      }
    },
    
    changeCheck(item, val) {
      let checkItem = JSON.parse(JSON.stringify(item));
      if (val == true) {
        let jsonData = JSON.parse(JSON.stringify(this.foldersTree));
        this.foldersTree = jsonData.map((m) => {
          if (m.children && m.children.length > 0) {
            m.children.map((v) => {
              if (checkItem.index != v.index) {
                v.checkVal = false;
              }
            });
          }
          return m;
        });
      }
    },

    checkBtn() {
      let checkRow = JSON.parse(JSON.stringify(this.$refs.table.getSelectionRows()));
      tempTasksPlay({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          if (res.result.length > 0) {
            this.renwuData = res.result;
          } else {
            ElMessage({
              type: "info",
              message: "暂无歌曲",
              customClass: "mzindex",
            });
          }
        }
      });
    },
  },
  created() {
    this.getDeviceList();
    getSongTable().then((res) => {
      if (!res.error) {
        this.foldersTree = res.result.filter((f) => {
          return f.type == true;
        });
      }
    });

    if (window.sessionStorage.getItem("userRole")) {
      this.permissions = JSON.parse(window.sessionStorage.getItem("userRole"));
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.button-box {
  & div {
    padding-left: 35px;
    margin: 28px 0;
    border-right: 1px solid #f5f5f5;
  }
}
.titleTop {
  margin: 10px 0;

  & i {
    width: 3px;
    height: 16px;
    background: #409eff;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }
}
.songList {
  width: 100%;
  height: calc(50vh - 100px);
  position: relative;
}
.operation {
  & > div:not(:last-child) {
    width: 50px;
    height: 50px;
    font-size: 13px;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  & > div:last-child {
    width: 100px;
    height: 50px;
    font-size: 13px;
    float: left;
    cursor: pointer;
  }
}
.yinliang {
  & > span {
    width: 26px;
    height: 26px;
    display: block;
    border-radius: 50px;
    border: 1px solid #000;
    float: left;
    text-align: center;
    padding-top: 1px;
    box-sizing: border-box;
  }
}
/deep/ .el-table__header .el-checkbox {
  display: none;
}

.jindu {
  width: 50px;
  height: 8px;
  border-radius: 8px;
  background: #ddd;
  display: inline-block;
  overflow: hidden;

  & i {
    height: 8px;
    display: inline-block;
    background: #268ffe;
    float: left;
  }
}

.renwu-box {
  background: #eff2f9;
  & > p:nth-child(1) {
    width: calc(100% - 30px);
    height: 26px;
    padding: 7px 15px;
    background: #3d7fff;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: flex;
      align-items: center;
      & > img {
        margin-left: 6px;
      }
    }
  }
  /deep/ & .el-table {
    margin: 0px 2%;
  }
  /deep/ & th {
    background: #eff2f9;
  }

  & .greyBg {
    height: 10px;
    background: #eff2f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.custom-tree-node {
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}

::v-deep .el-tree-node__content {
  height: 70px;
  line-height: 70px;
}

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #fff !important;
}
::v-deep .is-checked {
  background-color: #fff !important;
}
</style>
