
function val_seg(val) {
    var r = 0;
    val >>= 7;
    if (val & 0xf0) {
        val >>= 4;
        r += 4;
    }
    if (val & 0x0c) {
        val >>= 2;
        r += 2;
    }
    if (val & 0x02)
        r += 1;
    return r;
}
function alaw_to_16bitPcm(a_val) {
    var t;
    var seg;
    a_val = a_val ^ 0x55;
    t = a_val & 0x7f;
    if (t < 16) {
        t = (t << 4) + 8;
    } else {
        seg = (t >> 4) & 0x07;
        t = ((t & 0x0f) << 4) + 0x108;
        t <<= seg - 1;
    }
    return ((a_val & 0x80) ? t : -t);
}
function linear2alaw(pcm_val) {
    var mask;
    var seg;
    var aval;
    if (pcm_val >= 0) {
        mask = 0xD5;
    } else {
        mask = 0x55;
        pcm_val = -pcm_val;
        if (pcm_val > 0x7fff) {
            pcm_val = 0x7fff;
        }
    }

    if (pcm_val < 256) {
        aval = pcm_val >> 4
    } else {
        seg = val_seg(pcm_val);
        aval = (seg << 4) | ((pcm_val >> (seg + 3)) & 0x0f);
    }

    return aval ^ mask;
}
window.location.href


var ding
var time;
var db
var receiveData;
var player;
let web_img
let volume
let db_height
let ctx
var webscoke
var myurl;
var paramTime;
let radioCtx
let db_weight
let radioTimer
let errorStatus = "trueState";
var firstrecv = false;
let onerrorResult = [];
let volumes = 0;

export default class Recorder {
    constructor(stream, config) {
        //兼容
        window.URL = window.URL || window.webkitURL;
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        config = config || {};
        config.sampleBits = config.sampleBits || 16;   //采样数位 8, 16
        config.sampleRate = config.sampleRate || 8000; //采样率(1/6 44100)

        this.context = new (window.webkitAudioContext || window.AudioContext)();
        this.audioInput = this.context.createMediaStreamSource(stream);
        this.createScript = this.context.createScriptProcessor || this.context.createJavaScriptNode;
        this.recorder = this.createScript.apply(this.context, [1024, 1, 1]);

        this.audioData = {
            size: 0,          //录音文件长度
            buffer: [],    //录音缓存
            inputSampleRate: this.context.sampleRate,   //输入采样率
            inputSampleBits: 16,     //输入采样数位 8, 16
            outputSampleRate: config.sampleRate,   //输出采样率
            oututSampleBits: config.sampleBits,       //输出采样数位 8, 16
            input: function (data) {
                // console.log("执行input函数");
                this.buffer.push(new Float32Array(data));
                this.size += data.length;
            },
            compress: function () { //合并压缩

                //合并
                let data = new Float32Array(this.size);
                let offset = 0;
                for (let i = 0; i < this.buffer.length; i++) {
                    data.set(this.buffer[i], offset);
                    offset += this.buffer[i].length;
                }
                this.buffer = [];
                this.size = 0;
                //压缩
                let compression = parseInt(this.inputSampleRate / this.outputSampleRate);
                // console.log('inputSampleRate', this.inputSampleRate);
                // console.log('outputSampleRate', this.outputSampleRate);
                // console.log('compression', compression);
                let length = data.length / compression;  //每隔这个比值6采样一次
                let result = new Float32Array(length);
                let index = 0, j = 0;
                while (index < length) {
                    result[index] = data[j];
                    j += compression;
                    index++;
                }
                return result;
            },
            encodeG711a: function () {
                var sampleRate = Math.min(this.inputSampleRate, this.outputSampleRate);
                var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
                // console.log('sampleRate', sampleRate);
                // console.log('sampleBits', sampleBits);
                var bytes = this.compress();
                var dataLength = bytes.length * (sampleBits / 8);
                var buffer = new ArrayBuffer(sampleBits * 2);
                var data = new DataView(buffer);

                var getLength = bytes.length * (sampleBits / 8);
                var getbuffer = new ArrayBuffer(getLength / 2);
                var g711_data = new DataView(getbuffer);
                var sum = 0;
                db = 0;
                var offset = 0;
                if (sampleBits === 8) {
                    // for(var i=0; i<bytes.length;i++,offset++){
                    //     var s = Math.max(-1,Math.min(1,bytes[i]));
                    //     var val = s < 0 ? s * 0x8000 : s * 0x7FFF;
                    //     val = parseInt(255 / (65535 / (val + 32768)));
                    //     data.setInt8(offset, val, true);
                    // }
                } else {
                    for (var i = 0; i < bytes.length; i++) {
                        var s = Math.max(-1, Math.min(1, bytes[i]));
                        data.setInt32(0, s < 0 ? s * 0x8000 : s * 0x7FFF);
                        g711_data.setUint8(i, linear2alaw(data.getInt32(0)), true);

                        sum += Math.abs(data.getInt16(0));
                        sum += Math.abs(data.getInt16(1));
                    }
                    if (sum > 0) {
                        sum = sum / (data.byteLength / 2);
                        db = Math.round(20.0 * Math.log10(sum));
                    }
                    //console.log('db:' + db,this.db);
                    volume = db
                }

                return g711_data;
            },
        };
    }

    async readioStart(param) { //广播收音
        // console.log("执行readioStart函数");
        // console.log(param.wsradio,"---wsradio---");
        let self = this;
        myurl = param.wsparams
        this.audioInput.connect(this.recorder);
        this.recorder.connect(this.context.destination);
        //音频采集
        await this.initWebSocket(param.wsradio, param.resetStatus, self, param);

        this.recorder.onaudioprocess = function (e) {
            self.audioData.input(e.inputBuffer.getChannelData(0));
        };
        this.radioDraw();  //绘制音频音量动画
    }

    initWebSocket(url, flag, self, param) {
        // console.log(url,"--url--",flag,"--flag",self,"===self==");
        let that = this;
        // if (webscoke) {
        //     webscoke.close();
        // }
        webscoke = new WebSocket(url);
        webscoke.binaryType = 'arraybuffer';  //接收数据格式为二进制
        // webscoke.onopen = this.websocketonopen;
        webscoke.onopen = () => {
            that.sendradioMsg(self, param);
        }
        webscoke.onerror = this.websocketonerror;
        // webscoke.onclose = this.websocketclose(flag)、;
        webscoke.onclose = () => {
            this.websocketclose(flag)
        }
        //    console.log(webscoke.onclose,"查看断开连接");
        firstrecv = false;
    };
    websocketonopen(self) {
        // console.log("open函数");
        // player = new PCMPlay();
        // player.init({
        //     encoding: '16bitInt',
        //     channels: 1,
        //     sampleRate: 8000,
        //     flushingTime: 1280
        // })
    };
    returnStatus(param) {
        // console.log(param,"调用returnSatatus");
        return param;

        // console.log(param,"收到状态");
    };
    websocketonerror = (e) => { //拦截错误
        //console.log(e,"websocket连接发生错误");
        //连接发生错误，返回状态
        if (e) {
            errorStatus = "falseState";
            clearInterval(time);
            time = null;
            clearInterval(ding);
            clearInterval(paramTime);
            clearInterval(radioTimer);
            ding = null;

            radioTimer = null;
            paramTime = null;
            if (ctx) {
                ctx.clearRect(0, 37, 17, -db_height); //清空给定矩形内的像素
            }
            if (radioCtx) {
                radioCtx.clearRect(0, 0, 25, 50);
            }
        } else {
            errorStatus = "";
        }

        onerrorResult.push(this.returnStatus(errorStatus));
        // console.log(onerrorResult,"拦截到错误");
        return onerrorResult;
    };


    websocketsend(data, self) { //发送数据
        //   console.log(data,"发送数据");
        try {
            webscoke.send(data);
            // console.log(data,"websocketsend--send");
        } catch (e) {
        }

    };

    websocketonmessage = (e) => {  //接收数据  箭头函数，改变this指向
        if (firstrecv == false) {
            receiveData = new ArrayBuffer(0);
            let self = this;
            var array = self.audioData.decodeG711a(receiveData);
            var data = new Uint8Array(array.buffer);
            player.feed(data);  //调用player播放音频
            firstrecv = true;
        }
        receiveData = e.data;
        //    (receiveData,"----接收的数据----");
        let self = this;
        var array = self.audioData.decodeG711a(receiveData);
        var data = new Uint8Array(array.buffer);
        player.feed(data);  //调用player播放音频

    };
    websocketclose = (param) => {
        clearInterval(time);
        clearInterval(ding);
        clearInterval(paramTime);
        clearInterval(radioTimer);
        ding = null;
        time = null;
        radioTimer = null;
        paramTime = null;
        if (ctx) {
            ctx.clearRect(0, 37, 17, -db_height); //清空给定矩形内的像素
        }
        if (radioCtx) {
            radioCtx.clearRect(0, 0, 25, 50);
        }
        if (webscoke) {
            try {
                webscoke.close();
            } catch (el) {
                alert("对讲发生异常，终止对讲")
                console.log(el, "关闭websocket连接异常，抛出异常");
            }
            console.log("websocket关闭成功");
        }
        // player.destroy();
        param();
        //console.log("websocket关闭");

        //   console.log("websocket连接关闭");
        //   errorStatus = "falseState";
        //   onerrorResult.push(this.returnStatus(errorStatus));
        //   return onerrorResult;
        //   closeConnect="closeSate"
        //    return closeConnect;
    };
    sendradioMsg(self, param) { //发送数据
        //再发送采集的音频数据
        var flag = false;
        time = setInterval(function () {
            // if (webscoke.readyState === webscoke.OPEN) {
            if (flag == false) {
                self.websocketsend(JSON.stringify(param.wsparams), self);
                flag = true;
            }
            var array = self.audioData.encodeG711a();
            self.audioData.buffer = [];
            self.audioData.size = 0;
            try {
                self.websocketsend(array, self);
            } catch (e) {
                console.log(e);
            }
            // }
        }, 80)
    }

    //停止
    stop() {
        // clearInterval(time);
        if (webscoke) {
            webscoke.close(); //关闭websocket连接
            this.recorder.disconnect(); //断开
            Vue.delete(webscoke); //删除websocket对象，断开连接
        }

        // console.log('websocket断开连接');
    }
    //清理缓存的录音数据
    clear() {
        this.audioData.buffer = [];
        this.audioData.size = 0;
    };
    static throwError(message) {
        // console.log("Error:" + message);
        throw new function () {
            this.toString = function () {
                return message;
            }
        };
    }
    static canRecording() {
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
        return (navigator.getUserMedia);
    }
    static get(callback, config) {

        if (callback) {
            if (Recorder.canRecording()) {
                navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
                navigator.getUserMedia(
                    { audio: true },  //只启用音频
                    function (stream) {
                        //console.log(stream,"---stream---");
                        let rec = new Recorder(stream, config);
                        callback(rec);
                    },
                    function (error) {
                        switch (error.code || error.name) {
                            case 'PERMISSION_DENIED':
                            case 'PermissionDeniedError':
                                alert(this.$t("newlyadded_3.Theuserrefusedtoprovideinformation"));
                                Recorder.throwError(this.$t("newlyadded_3.Theuserrefusedtoprovideinformation"));
                                break;
                            case 'NOT_SUPPORTED_ERROR':
                            case 'NotSupportedError':
                                alert(this.$t("newlyadded_3.Thebrowserdoesnotsupporthardwaredevices"))
                                Recorder.throwError(this.$t("newlyadded_3.Thebrowserdoesnotsupporthardwaredevices"));
                                break;
                            case 'MANDATORY_UNSATISFIED_ERROR':
                            case 'MandatoryUnsatisfiedError':
                                alert(this.$t("newlyadded_3.Thespecifiedhardwaredevicecouldnotbefound"))
                                Recorder.throwError(this.$t("newlyadded_3.Thespecifiedhardwaredevicecouldnotbefound"));
                                break;
                            default:
                                alert(this.$t("newlyadded_3.UnabletoturnonthemicrophoneExceptioninformation") + (error.code || error.name));
                                Recorder.throwError(this.$t("newlyadded_3.UnabletoturnonthemicrophoneExceptioninformation") + (error.code || error.name));
                                break;
                        }
                    }
                );
            } else {
                alert(this.$t("newlyadded_3.Thecurrentbrowserdoesnotsupportrecording"));
                Recorder.throwError(this.$t("newlyadded_3.Thecurrentbrowserdoesnotsupportrecording"));
                return;
            }
        }
    };

    radioDraw() { //绘制广播音量动态图
        // console.log("执行广播音量动态图");
        // var volumes


        function radioTime() {
            //     radioCtx.clearRect(0, 0, 12, 50); //清除该位置的绘图，或者设置为透明色
            //     // 清除
            // radioCtx.globalCompositeOperation = "deatination-out";
            db_weight = volume; //采集音频时的音量
            volumes = volume * 3 / 5;
            console.log(volumes);
            //     if (volumes < 0) {
            //         volumes = -volumes
            //     }
            //     else if (volumes > 22) {
            //         volumes = 22;
            //     }
            //     // console.log(volumes,"===音量==");

            //     // radioCtx.fillRect(0,23,volumes,15); //把位置为(0,20)位置大小为15*volum
            //     //volumes 最大为30
            //     if (canvas.getContext) {
            //         radioCtx.beginPath();
            //         radioCtx.moveTo(0, 30);
            //         // radioCtx.lineTo(22,14);
            //         //radioCtx.lineTo(volumes,14);
            //         radioCtx.lineTo(volumes, 36 - volumes)
            //         // radioCtx.lineTo(22,53);
            //         radioCtx.lineTo(volumes, 32 + volumes);
            //         radioCtx.lineTo(0, 33);
            //         radioCtx.closePath();
            //         radioCtx.fill();
            //     }
        }
        // var canvas = document.getElementById("radioCanvas");
        // radioCtx = canvas.getContext("2d");
        // radioTimer = setInterval(radioTime, 200);
        // var radioGrd = radioCtx.createLinearGradient(0, 12, 22, 14); //渐变开始到结束的坐标
        // radioGrd.addColorStop(0.1, "yellow");
        // radioGrd.addColorStop(0.2, "red");
        // radioGrd.addColorStop(0.3, "orange");
        // radioGrd.addColorStop(0.4, "green");
        // radioGrd.addColorStop(0.5, "red");
        // radioGrd.addColorStop(0.6, "yellow");
        // radioGrd.addColorStop(0.7, "#FF4500");
        // radioGrd.addColorStop(0.8, "orange");
        // radioGrd.addColorStop(0.9, "green");
        // radioGrd.addColorStop(1, "red");
        // radioCtx.fillStyle = radioGrd; //设置图形的轮廓色
    }
}