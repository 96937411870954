// config/index.js
const env = process.env.NODE_ENV;
const configObj = {
  production: {
    // baseUrl: "http://113.106.82.243/"
    baseUrl: window.location.origin + '/',
    baseUrl2: window.location.origin
  },
  development: {
    baseUrl: 'http://113.106.82.243:8086/',
    baseUrl2: 'https://120.24.222.206/',
  }
};
export default { ...configObj[env] };