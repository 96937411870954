<template>
  <div class="warpper-content-box">
    <el-row :gutter="50" style="width: 100%">
      <el-col :span="3" class="button-box">
        <div>
          <el-button @click="addTask" style="width: 6.875rem; color: #666" type="text">
            <svg-icon name="page2Icon1" size="28" color="#333" />
            &nbsp;添加任务
          </el-button>
        </div>
        <div>
          <el-button @click="editRow()" style="width: 6.875rem; color: #666" type="text">
            <svg-icon name="page2Icon2" size="22" color="#333" />&nbsp;编辑任务
          </el-button>
        </div>
        <div>
          <el-button @click="removeRow()" style="width: 6.875rem; color: #666" type="text">
            <svg-icon name="page2Icon3" size="20" color="#333" />&nbsp;删除任务
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="palyMuse()" style="width: 6.875rem; color: #666" type="text">
            <svg-icon name="page2Icon0" size="22" color="#333" />
            &nbsp;手动播放
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="stopMuse()" style="width: 6.875rem; color: #666" type="text">
            <svg-icon name="page2Icon4" size="28" color="#333" />&nbsp;手动停止
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="pauseMuse()" style="width: 6.875rem; color: #666" type="text">
            <svg-icon name="page2Icon5" size="22" color="#333" />&nbsp;手动暂停
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button
            @click="songSelect(-1)"
            style="width: 6.875rem; color: #666"
            type="text"
          >
            <svg-icon name="page2Icon6" size="22" color="#333" />&nbsp;&nbsp;上一曲
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button
            @click="songSelect(+1)"
            style="width: 6.875rem; color: #666"
            type="text"
          >
            <svg-icon name="page2Icon7" size="22" color="#333" />&nbsp;&nbsp;下一曲
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1" style="text-indent: 1em">
          <el-button @click="setVolume('+')" style="width: 1.875rem; color: #666" type="text">
            <svg-icon name="page2Icon8" size="22" color="#333" />
          </el-button>
          <el-button
            @click="setVolume('-')"
            style="width: 1.875rem; margin-left: 1.25rem; color: #666"
            type="text"
          >
            <svg-icon name="page2Icon9" size="22" color="#333" />
          </el-button>
          <p style="color: #666">音量步进值</p>
        </div>
      </el-col>
      <el-col :span="21">
        <sortTable
          ref="timeTable"
          :check="true"
          style="margin: 1.25rem auto"
          :tableData="tableData"
        ></sortTable>
      </el-col>
    </el-row>

    <el-dialog
      v-model="taskDialog"
      :fullscreen="true"
      :before-close="handleClose"
      title="新增任务"
    >
      <el-form :model="taskForm" label-width="5.625rem" class="task-form">
        <el-row :gutter="35">
          <el-col :span="16">
            <chooseEquipment ref="equipment"></chooseEquipment>
          </el-col>
          <el-col :span="8">
            <!-- <el-tree :data="songList" show-checkbox ref="songTree" style="width: 100%" :props="defaultProps"></el-tree> -->
            <div class="renwu-box">
              <p>
                <span
                  >音乐曲库
                  <img
                    style="float: right"
                    src="../assets/image/renwuIcon.png"
                    alt=""
                    srcset=""
                /></span>
                <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
              </p>

              <div style="background: #fff">
                <el-collapse
                  style="padding: 1.25rem"
                  v-if="songList.length > 0"
                  v-model="activeNames"
                  accordion
                >
                  <el-collapse-item
                    :name="index + 1"
                    v-for="(item, index) in songList"
                    :key="index"
                  >
                    <template #title>
                      <img
                        width="26"
                        style="margin: 0 0rem 0 .9375rem"
                        src="../assets/image/renwuIcon5.png"
                        alt=""
                        srcset=""
                      />
                      {{ item.name }}
                    </template>
                    <ul v-if="item.children && item.children.length > 0">
                      <li
                        style="text-indent: 1em; height: 2.1875rem; line-height: 2.1875rem"
                        v-for="(item2, index2) in item.children"
                        :key="index2"
                      >
                        <label for=""
                          >{{ item2.name
                          }}<el-checkbox
                            style="float: right; margin-right: 1.875rem"
                            label=""
                            v-model="item2.check"
                          ></el-checkbox
                        ></label>
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>

            <div class="renwu-box" style="margin-top: 1.5625rem; padding-bottom: 1.25rem">
              <p>
                <span
                  >任务栏
                  <img
                    style="float: right"
                    src="../assets/image/renwuIcon.png"
                    alt=""
                    srcset=""
                /></span>
                <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
              </p>
              <el-form-item label="任务名称" style="margin-top: .625rem">
                <el-input
                  style="width: 18.75rem"
                  v-model="taskForm[0]"
                  placeholder="请输入任务名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="音量">
                <el-input-number
                  v-model="taskForm[1]"
                  placeholder="请输入音量"
                  :min="0"
                  :max="10"
                  controls-position="right"
                  size="large"
                />
                <el-checkbox style="margin-left:.625rem" v-model="checked1" label="默认音量" size="large" />
              </el-form-item>
              <el-form-item label="播放模式">
                <el-select v-model="taskForm[2]" placeholder="请选择模式" size="large">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- taskForm[3] 类型 -->
              <el-form-item label="开始时间">
                <el-time-picker
                  value-format="HH:mm:ss"
                  v-model="taskForm[4]"
                  placeholder="开始时间"
                />
              </el-form-item>
              <el-form-item label="结束时间">
                <el-time-picker
                  value-format="HH:mm:ss"
                  v-model="taskForm[5]"
                  placeholder="结束时间"
                />
              </el-form-item>
              <el-form-item label="开始日期">
                <el-date-picker
                  value-format="YYYY-MM-DD"
                  v-model="taskForm[6]"
                  type="date"
                  placeholder="选择日期"
                />
              </el-form-item>
              <el-form-item label="结束日期">
                <el-date-picker
                  value-format="YYYY-MM-DD"
                  v-model="taskForm[10]"
                  type="date"
                  placeholder="选择日期"
                />
              </el-form-item>
              <el-form-item label="星期选择">
                <el-checkbox-group v-model="taskForm[7]">
                  <el-checkbox :label="0">星期一</el-checkbox>
                  <el-checkbox :label="1">星期二</el-checkbox>
                  <el-checkbox :label="2">星期三</el-checkbox>
                  <el-checkbox :label="3">星期四</el-checkbox>
                  <el-checkbox :label="4">星期五</el-checkbox>
                  <el-checkbox :label="5">星期六</el-checkbox>
                  <el-checkbox :label="6">星期日</el-checkbox>
                </el-checkbox-group>
              </el-form-item>

              <el-form-item style="text-align: right" label="">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="submitTasks" type="primary">确认</el-button>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      v-model="taskDialog2"
      :fullscreen="true"
      :before-close="handleClose2"
      title="编辑任务"
    >
      <el-form :model="taskForm2" label-width="5.625rem" class="task-form">
        <el-row :gutter="35">
          <el-col :span="16">
            <chooseEquipment :echoData="taskForm2[9]" ref="equipment2"></chooseEquipment>
          </el-col>
          <el-col :span="8">
            <div class="renwu-box">
              <p>
                <span
                  >音乐曲库
                  <img
                    style="float: right"
                    src="../assets/image/renwuIcon.png"
                    alt=""
                    srcset=""
                /></span>
                <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
              </p>
              <el-collapse
                style="padding: 1.25rem"
                v-if="songList2.length > 0"
                v-model="activeNames2"
                accordion
              >
                <el-collapse-item
                  :name="index + 1"
                  v-for="(item, index) in songList2"
                  :key="index"
                >
                  <template #title>
                    <img
                      width="26"
                      style="margin: 0 0rem 0 .9375rem"
                      src="../assets/image/renwuIcon5.png"
                      alt=""
                      srcset=""
                    />
                    {{ item.name }}
                  </template>
                  <ul v-if="item.children && item.children.length > 0">
                    <li
                      style="text-indent: 1em; height: 2.1875rem; line-height: 2.1875rem"
                      v-for="(item2, index2) in item.children"
                      :key="index2"
                    >
                      <label for=""
                        >{{ item2.name
                        }}<el-checkbox
                          style="float: right; margin-right: 1.875rem"
                          label=""
                          v-model="item2.check"
                        ></el-checkbox
                      ></label>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </div>

            <div class="renwu-box" style="margin-top: 1.5625rem; padding-bottom: 1.25rem">
              <p>
                <span
                  >任务栏
                  <img
                    style="float: right"
                    src="../assets/image/renwuIcon.png"
                    alt=""
                    srcset=""
                /></span>
                <img src="../assets/image/renwuIcon2.png" alt="" srcset="" />
              </p>
              <el-form-item label="任务名称" style="margin-top: 1.25rem">
                <el-input
                  style="width: 18.75rem"
                  v-model="taskForm2[1]"
                  placeholder="请输入任务名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="音量">
                <el-input-number
                  v-model="taskForm2[2]"
                  placeholder="请输入音量"
                  :min="0"
                  :max="10"
                  controls-position="right"
                  size="large"
                />
              </el-form-item>
              <el-form-item label="播放模式">
                <el-select v-model="taskForm2[3]" placeholder="请选择模式" size="large">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- taskForm2[4] 类型 -->
              <el-form-item label="开始时间">
                <el-time-picker
                  value-format="HH:mm:ss"
                  v-model="taskForm2[5]"
                  placeholder="开始时间"
                />
              </el-form-item>
              <el-form-item label="结束时间">
                <el-time-picker
                  value-format="HH:mm:ss"
                  v-model="taskForm2[6]"
                  placeholder="结束时间"
                />
              </el-form-item>
              <el-form-item label="开始日期">
                <el-date-picker
                  value-format="YYYY-MM-DD"
                  v-model="taskForm2[7]"
                  type="date"
                  placeholder="选择日期"
                />
              </el-form-item>
              <el-form-item label="结束日期">
                <el-date-picker
                  value-format="YYYY-MM-DD"
                  v-model="taskForm2[11]"
                  type="date"
                  placeholder="选择日期"
                />
              </el-form-item>
              <el-form-item label="星期选择">
                <el-checkbox-group v-model="taskForm2[8]">
                  <el-checkbox :label="0">星期一</el-checkbox>
                  <el-checkbox :label="1">星期二</el-checkbox>
                  <el-checkbox :label="2">星期三</el-checkbox>
                  <el-checkbox :label="3">星期四</el-checkbox>
                  <el-checkbox :label="4">星期五</el-checkbox>
                  <el-checkbox :label="5">星期六</el-checkbox>
                  <el-checkbox :label="6">星期日</el-checkbox>
                </el-checkbox-group>
              </el-form-item>

              <el-form-item label="">
                <el-button @click="handleClose2">取消</el-button>
                <el-button @click="submitTasks2" type="primary">确认</el-button>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import sortTable from "../components/sortTable.vue";
import {
  tasksAdd,
  getTasksDetails,
  tasksMod,
  tasksDel,
  tasksPlay,
  tasksPuse,
  tasksStop,
  setTasksVolume,
  tasksSongSelect,
  getSongTable,
} from "../utils/api";
import chooseEquipment from "../components/chooseEquipment.vue";
import { ElMessage, ElMessageBox } from "element-plus/lib/components";
export default {
  components: { sortTable, chooseEquipment },
  props: {},
  data() {
    return {
      activeNames: "",
      activeNames2: "",
      defaultProps: {
        label: "name",
        children: "children",
      },
      checked1:true,
      songList: [],
      songList2: [],
      tableData: [],
      taskDialog: false,
      taskDialog2: false,
      taskForm: ["", 7, 1, "", "", "", [], [], [],""],
      taskForm2: [0, "", 7, 1, "", "", "", [], [], [],""],
      options: [
        {
          label: "单曲循环",
          value: 0,
        },
        {
          label: "列表循环",
          value: 1,
        },
        {
          label: "随机播放",
          value: 2,
        },
        {
          label: "顺序播放",
          value: 3,
        },
      ],
      permissions: [],
      inter: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleCurrentChange(row) {
      console.log(row);
    },
    songSelect(a) {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksSongSelect({ params: [checkRow[0][0], a] }).then((res) => {
        let tips = a == -1 ? "上一首" : "下一首";
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `${tips}`,
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    setVolume(a) {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      setTasksVolume({ params: [checkRow[0][0], Number(a + 1)] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `音量${a}1`,
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    pauseMuse() {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksPuse({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "暂停播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    stopMuse() {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksStop({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "停止播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    palyMuse() {
      let checkRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!checkRow || checkRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      tasksPlay({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "正在播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    editRow() {
      let rowData = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      )[0];
      if (!rowData || rowData.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      this.songList2 = this.songList2.map((m) => {
        if (m.children && m.children.length > 0) {
          m.children.map((m2) => {
            if (rowData[12].indexOf(m2.index) > -1) {
              m2.check = true;
            }
          });
        }
        return m;
      });
      this.taskDialog2 = true;

      this.taskForm2 = [
        rowData[0],
        rowData[1],
        rowData[4],
        rowData[5],
        0, //类型
        rowData[7],
        rowData[8],
        rowData[9],
        rowData[10],
        rowData[11],
        rowData[12],
        rowData[13],//结束时间
      ];
      let _that = this;
      setTimeout(function() {
        _that.taskForm2[9] = rowData[9];
      },500)
    },
    removeRow() {
      let itemRow = JSON.parse(
        JSON.stringify(this.$refs.timeTable.$refs.table.getSelectionRows())
      );
      if (!itemRow || itemRow.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      ElMessageBox.confirm("是否删除当前任务？", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          tasksDel({ params: [itemRow[0][0]] }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "info",
                message: "删除成功！",
                customClass: "mzindex",
              });
              this.getTasksSummary();
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除！",
            customClass: "mzindex",
          });
        });
    },
    handleClose() {
      this.taskDialog = false;
      this.taskForm = ["", 10, 1, "", "", "", "", [], [], [],""];
    },
    handleClose2() {
      // this.$refs.songTree2.setCheckedNodes([]);
      this.taskDialog2 = false;
      this.taskForm2 = ["", 0, 1, "", "", "", [], [], [],""];
    },
    submitTasks2() {
      let limits = this.$refs.equipment2.devicelist.filter(m => m[6] && m[6] == true).map(m => m[1]);
      let musicTree2 = [];
      this.songList2.map((f) => {
        if (f.children && f.children.length > 0) {
          f.children.map((m) => {
            if (m.check == true) {
              musicTree2.push(m.index);
            }
          });
        }
      });
      this.taskForm2[4] = 0;
      this.taskForm2[9] = limits;
      this.taskForm2[10] = musicTree2;
      
      tasksMod({ params: this.taskForm2 }).then((res) => {
        if (!res.error) {
          this.taskDialog2 = false;
          ElMessage({
            message: "编辑成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    submitTasks() {
      let limits = this.$refs.equipment.checkDevice;
      let musicTree = [];
      this.songList.forEach((f) => {
        if (f.children) {
          f.children.map((m) => {
            if (m.check == true) {
              musicTree.push(m.index);
            }
          });
        }
      });
      this.taskForm[3] = 0;
      this.taskForm[8] = limits;
      this.taskForm[9] = musicTree;
      if(!this.taskForm[0]){
        ElMessage({message: "请输入任务名称",type: "error",customClass: "mzindex",});
        return false;
      }
      if(!this.taskForm[1]){
        ElMessage({message: "请输入音量",type: "error",customClass: "mzindex",});
        return false;
      }
      if(!this.taskForm[2]){
        ElMessage({message: "请选择模式",type: "error",customClass: "mzindex",});
        return false;
      }
      if(!this.taskForm[4]){
        ElMessage({message: "请选择开始时间",type: "error",customClass: "mzindex",});
        return false;
      }
      if(!this.taskForm[5]){
        ElMessage({message: "请选择结束时间",type: "error",customClass: "mzindex",});
        return false;
      }
      if(!this.taskForm[6]){
        ElMessage({message: "请选择日期",type: "error",customClass: "mzindex",});
        return false;
      }
      if(this.taskForm[7].length == 0){
        ElMessage({message: "请选择星期",type: "error",customClass: "mzindex",});
        return false;
      }
      if(this.taskForm[8].length == 0){
        ElMessage({message: "请选择设备",type: "error",customClass: "mzindex",});
        return false;
      }

      if(this.checked1){
        this.taskForm[1] = 10
      }
      tasksAdd({ params: this.taskForm }).then((res) => {
        if (!res.error) {
          this.taskDialog = false;
          ElMessage({
            message: "新增成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    getTasksSummary() {
      getTasksDetails({ params: [0] }).then((res) => {
        if (!res.error) {
          this.tableData = res.result;
        }
      });

      getSongTable().then((res) => {
        if (!res.error) {
          let arrList = res.result.map((m) => {
            if (m.children && m.children.length > 0) {
              m.children.map((m2) => {
                m2.check = false;
              });
            }
            return m;
          });
          this.songList = arrList;
          this.songList2 = arrList;
        }
      });
    },
    addTask() {
      this.taskDialog = true;
    },
  },
  created() {
    this.getTasksSummary();

    if (window.sessionStorage.getItem("userRole")) {
      this.permissions = JSON.parse(window.sessionStorage.getItem("userRole"));
    }
  },
  mounted() {
    let that = this;
    this.inter = setInterval(function () {
      getTasksDetails({ params: [0] }).then((res) => {
        if (!res.error) {
          that.tableData = that.tableData.map((m) => {
            res.result.map((m2) => {
              if (m[0] == m2[0]) {
                m[3] = m2[3];
              }
            });
            return m;
          });
        }
      });
    }, 10000);
  },
  unmounted() {
    clearInterval(this.inter);
  },
};
</script>
<style lang="scss" scoped>
.button-box {
  & > div {
    padding-left: 2.1875rem;
    margin: 1.75rem 0;
  }
}
/deep/ .el-table {
  border-top-left-radius: .625rem;
  border-top-right-radius: .625rem;
}
/deep/ .el-table thead tr th {
  background: #3d7fff;
  color: #fff;
}
/deep/ .el-table thead tr td {
  background: #eff2f9;
}
/deep/ .el-table--enable-row-transition .el-table__body td.el-table__cell {
  background: #f5f5f5;
}

.task-form {
  margin: 1%;
  padding: 1.75rem;
  border-radius: .625rem;
  background: #eff2f9;
}

.renwu-box {
  background: #fff;
  & > p:nth-child(1) {
    width: calc(100% - 1.875rem);
    height: 1.625rem;
    padding: .4375rem .9375rem;
    background: #3d7fff;
    color: #fff;
    border-top-left-radius: .625rem;
    border-top-right-radius: .625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      display: flex;
      align-items: center;
      & > img {
        margin-left: .375rem;
      }
    }
  }
}

::v-deep .el-collapse-item__header {
  background: #eff2f9;
  text-indent: .625rem;
  border-radius: .1875rem;
}
</style>
