<template>
  <div>
    <el-table ref="table" style="width:100%;background:#eff2f9" height="calc(100vh - 200px)" @select="dialogCheck" :data="tableData" row-key="id" :row-class-name="tableRowClassName">
      <el-table-column v-if="check" type="selection" width="55" />
      <el-table-column v-for="(item, index) in colRow" :key="`col_${index}`" :prop="item.prop" :label="item.label">
        <template #default="scope">
          <span v-if="index < 4">{{ scope.row[index + 1] }}</span>
          <span v-else-if="index == 4">{{ scope.row[index + 2] == 1 ? "噪声告警" : "红外告警" }}</span>
          <span v-else>{{ scope.row[index + 2] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="handleBtn" width="230px" fixed="right">
        <template #default="scope">
          <el-button style="margin-left: 8px" type="text" @click="palyMuse(scope.row)">播放</el-button>
          <el-button style="margin-left: 8px" type="text" @click="pauseMuse(scope.row)">暂停</el-button>
          <el-button style="margin-left: 8px" type="text" @click="stopMuse(scope.row)">停止</el-button>
          <el-button style="margin-left: 8px" type="text" @click="editRow(scope.row)">编辑</el-button>
          <el-button style="margin-left: 8px" type="text" @click="removeRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Sortable from "sortablejs";
export default {
  data() {
    return {
      statusArr: {
        "-1": "暂时",
        0: "停止",
        1: "播放",
      },
      statusArr2: ["顺序播放", "列表循环", "随机播放"],
      oldIndex: null,
      newIndex: null,
      colRow: [
        { label: "任务名称", prop: "name" },
        { label: "创建人", prop: "username" },
        { label: "状态", prop: "status" },
        { label: "音量", prop: "volume" },
        { label: "类型", prop: "moshi" },
        // { label: "类型", prop: "type" },
        { label: "开始时间", prop: "startTime", width: "120" },
        { label: "结束时间", prop: "endTime", width: "120" },
        { label: "日期", prop: "enddate", width: "120" },
        // { label: "星期", prop: "schedule" },
        // { label: "设备", prop: "dataType" },
        // { label: "曲目", prop: "content", width: "120" },
      ],
    };
  },
  props: {
    check: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: [],
    },
    handleBtn: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // 页面挂载完成，开始创建拖拽
    this.init();

    console.log(this.tableData);
  },
  methods: {
    editRow(item) {
      this.$emit("editRow", item);
    },
    removeRow(item) {
      this.$emit("removeRow", item);
    },
    palyMuse(item) {
      this.$emit("palyMuse", item);
    },
    stopMuse(item) {
      this.$emit("stopMuse", item);
    },
    pauseMuse(item) {
      this.$emit("pauseMuse", item);
    },
    init() {
      // 1. 获取拖拽元素的父元素
      // 因为使用的element的table 所以可直接获取tbody
      let el = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      // 2. 创建并配置相关参数
      var sortable = new Sortable(el, {
        // 此处进行配置 及 回调函数的使用
        // 因为我的需求是只有按住手柄才能进行拖拽，故而设置此参数
        handle: ".handle_move", // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
        // 我的需求是行拖拽，所以该值设置为行class
        draggable: ".el-table__row", // 允许拖拽的项目类名
        // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
        // 拖拽中 回调函数
        onMove(customEvent) {
          // 禁止在拖拽过程中交换位置
          // 可将初始位置及目标位置进行记录
          _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
          _this.newIndex = customEvent.related.rowIndex; // 目标位置
          return false; // 不进行交换
          // 返回值有三个
          // return false; — for cancel
          // return -1; — insert before target
          // return 1; — insert after target
        },

        // 拖拽结束，调整位置
        onEnd() {
          // 在拖拽结束后，获取初始及目标位置
          const { newIndex, oldIndex } = _this;
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
          // 此时记得把交换后的数据，发送给后端进行记录

          // 拖拽完成，初始位置及目标位置重置
          _this.newIndex = null;
          _this.oldIndex = null;
        },
      });
    },
    // 目标位置高亮
    // 给目标行 添加高亮样式
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === this.newIndex) {
        return "warning-row";
      }
      return "";
    },

    handleCurrentChange(row) {
      console.log(JSON.parse(JSON.stringify(row)));
      this.$emit("currentChange", row);
    },

    dialogCheck(selection, row) {
      this.$refs.table.clearSelection();
      this.selectioned = {}; //这里清除选择的记忆；主要是避免取消掉原来选中的还残留原来选中的东西
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return;
      }
      if (row) {
        this.selectioned = row;
        this.$refs.table.toggleRowSelection(row, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.handle_move {
  cursor: pointer;
}
.jindu {
  width: 50px;
  height: 8px;
  border-radius: 8px;
  background: #ddd;
  display: inline-block;
  overflow: hidden;

  & i {
    height: 8px;
    display: inline-block;
    background: lightgreen;
    float: left;
  }
}

/deep/ .el-table__header .el-checkbox {
  display: none;
}

/deep/ .el-table th {
  background: #3d7fff;
  color: #fff;
}
/deep/ .el-table {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  & td {
    background: #eff2f9;
  }
}
</style>
