<template>
  <div class="warpper-content-box">
    <el-row :gutter="50" style="width: 100%">
      <el-col :span="3" class="button-box">
        <div style="position: relative" v-if="permissions.indexOf(2) > -1">
          <el-button @click="startGuang" style="width: 110px; color: #999" type="text">
            <svg-icon name="guangbo" size="22" color="#333" />
            &nbsp;开始广播
          </el-button>

          <div class="loadBtn" v-if="loadBtn">
            <el-icon class="is-loading"> <Loading /> </el-icon>录音中{{
              recorder.volumes
            }}
          </div>
        </div>

        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="endGuang" style="width: 110px; color: #999" type="text">
            <svg-icon name="stopGb" size="22" color="#333" />&nbsp;停止广播
          </el-button>
        </div>
        <div v-if="permissions.indexOf(2) > -1">
          <el-button @click="setVol('+')" type="text" style="width: 50px; color: #999">
            <svg-icon name="page2Icon8" size="22" color="#333" />
          </el-button>
          <el-button @click="setVol('-')" type="text" style="width: 50px; color: #999">
            <svg-icon name="page2Icon9" size="22" color="#333" />
          </el-button>
          <p style="text-indent: 13px; color: #999">音量步进值</p>
        </div>
      </el-col>
      <el-col
        :span="21"
        style="
          margin: 18px 0;
          height: calc(100vh - 120px);
          background: #eff2f9;
          border-radius: 5px;
        "
      >
        <el-row>
          <el-col :span="6">
            <el-tree
              style="width: calc(100% - 20px); background: #eff2f9; margin-top: 20px"
              default-expand-all
              :highlight-current="true"
              @node-click="handleCheckChange"
              :default-checked-keys="[-1]"
              node-key="index"
              ref="areaTree"
              :data="areaDegree"
              :props="defaultProps"
            >
              <template #default="{ node, data }">
                <div class="custom-tree-node">
                  <img
                    width="24"
                    src="../assets/image/folder.png"
                    alt=""
                    srcset=""
                  />&nbsp;&nbsp;
                  {{ node.label }}
                </div>
              </template>
            </el-tree>
          </el-col>
          <el-col :span="18">
            <equipmentList
              :isCheck="true"
              :equipment="devicelist"
              @searchKey="searchKeyword"
              ref="shebeiList"
              style="width: 200px; margin-top: 30px"
            ></equipmentList>
            <!-- <ElPagination
              :total="total"
              :current-page="pageNum + 1"
              :page-count="pageSize"
              @current-change="currentChange"
            ></ElPagination> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>

  <!-- 停止录音 -->
</template>
<script>
import Volume from "../components/volume.vue";
import equipmentList from "../components/equipmentList.vue";
import {
  getDeviceByArea,
  getAreaDegree,
  setTasksVolume,
  tasksPuse,
  tempTasksStop,
  tempTasksPlay,
  setDeviceVolume,
  broadcastCall,
} from "../utils/api";
import { ElMessage } from "element-plus";
// import Recorder from "js-audio-recorder";
import urlConfig from "../utils/url";
import { Loading } from "@element-plus/icons-vue";
import Record from "../assets/lib/record";
export default {
  components: { equipmentList, Loading, Volume },
  props: {},
  data() {
    return {
      renwuData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      areaTree: [],
      activeNames: [],
      areaDegree: [],
      devicelist: [],
      devicelistAll:[],
      total: 0,
      pageNum: 0,
      pageSize: 0,

      sessionId: "",
      // recorder: null, //录音数据
      websock: null, //webstock数据
      deviceId: [],
      loadBtn: false,
      interval: null,

      recorder: new Record(),
      radioParameter: [],
      getRadioststeTimer: null,
      permissions: [],
      wsradio:""
    };
  },
  watch: {},
  computed: {},
  methods: {
    searchKeyword(val) {
      if(val){
        this.devicelist = this.devicelist.filter((m) => m[1].indexOf(val) > -1 || m[2].indexOf(val) > -1);
      }else{
        this.devicelist = this.devicelistAll
      }
    },
    startGuang() {
      let _this = this;
      this.deviceId = this.$refs.shebeiList.checkDevice;
      if (this.deviceId == null || this.deviceId == "" || this.deviceId == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }
      this.loadBtn = true;
      //广播开始喊话
      // const wsradio = `wss://ip-pa.com/rtp/broadcast`;
      console.log(this.deviceId);
      var wsparams = [this.deviceId, window.localStorage.getItem("systemToken")];

      this.recorder.startRadio({
        success: (res) => {
          this.$message.success("开始喊话");
        },
        error: (res) => {
          this.$message.error("广播开启失败");
        },
        resetStatus: (res) => {
          this.loadBtn = false;
          // console.log("重置状态");
        },
        wsradio: _this.wsradio,
        wsparams,
      });
    },
    endGuang() {
      //关闭广播
      this.recorder.endstop();
      this.loadBtn = false;
    },
    setVol(a) {
      let deviceId = this.$refs.shebeiList.checkDevice;
      if (deviceId == null || deviceId == "" || deviceId == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }
      setDeviceVolume({
        params: [deviceId, Number(a + 1)],
      }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "设置成功！",
            customClass: "mzindex",
          });
        }
      });
    },
    operation() {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksStop({ params: [checkIndex[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "停止播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    operation2() {
      let selectTab = JSON.parse(JSON.stringify(this.$refs.renwuTab.getSelectionRows()));
      if (selectTab.length > 0) {
        this.devicelist = this.devicelist.map((m) => {
          if (selectTab[0][4].length > 0) {
            selectTab[0][4].map((m2) => {
              if (m[3] == m2) {
                m[4] = true;
              }
            });
          }
          return m;
        });

        this.foldersTree = this.foldersTree.map((m) => {
          if (m.children) {
            m.children.map((m2) => {
              selectTab[0][5].map((m3) => {
                if (m2.index == m3) {
                  m2.checkVal = true;
                }
              });
            });
          }
          return m;
        });

        tempTasksPlay({ params: [selectTab[0][4], selectTab[0][5]] }).then((res) => {
          if (!res.error) {
            ElMessage({
              type: "success",
              message: "正在播放！",
              customClass: "mzindex",
            });
          }
        });
        return false;
      }

      let checkIndex = [];
      this.foldersTree.map((m) => {
        if (m.children) {
          m.children.map((m2) => {
            if (m2.checkVal == true) {
              checkIndex.push(m2.index);
            }
          });
        }
      });
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      let deviceId = this.$refs.shebeiList.checkDevice;

      if (deviceId == null || deviceId == "" || deviceId == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }
      tempTasksPlay({ params: [deviceId, checkIndex] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "正在播放",
            customClass: "mzindex",
          });
          this.getDeviceList();
        }
      });
    },
    operation3() {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      tasksPuse({ params: [checkIndex[0][0]] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: "暂停播放",
            customClass: "mzindex",
          });
          this.getTasksSummary();
        }
      });
    },
    operation4(a) {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      let boIndex = "";
      if (checkIndex[2] != "" && checkIndex[2] != undefined && checkIndex[2] != null) {
        this.foldersTree.map((m) => {
          if (m.children) {
            m.children.map((m2) => {
              if (m2.name == checkIndex[2]) {
                boIndex = m2.index;
              }
            });
          }
        });
        if (a == "+") {
          if (checkIndex[0][5].indexOf(boIndex) + 1 == checkIndex[0][5].length) {
            boIndex = checkIndex[0][5][0];
          } else {
            boIndex = checkIndex[0][5][checkIndex[0][5].indexOf(boIndex) + 1];
          }
        } else {
          if (checkIndex[0][5].indexOf(boIndex) - 1 < 0) {
            boIndex = checkIndex[0][5][checkIndex[0][5].length - 1];
          } else {
            boIndex = checkIndex[0][5][checkIndex[0][5].indexOf(boIndex) - 1];
          }
        }
      } else {
        boIndex = checkIndex[0][5][0];
      }
      tempTasksPlay({ params: [checkIndex[0][4], [boIndex]] }).then((res) => {
        let tips = a == "-" ? "上一首" : "下一首";
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `${tips}`,
            customClass: "mzindex",
          });
        }
      });
    },
    operation5(a) {
      let checkIndex = this.$refs.renwuTab.getSelectionRows();
      if (checkIndex == null || checkIndex == "" || checkIndex == undefined) {
        ElMessage({
          type: "error",
          message: "请先选择任务！",
          customClass: "mzindex",
        });
        return false;
      }
      setTasksVolume({ params: [checkIndex[0][0], Number(a + 5)] }).then((res) => {
        if (!res.error) {
          ElMessage({
            type: "success",
            message: `音量${a}1`,
            customClass: "mzindex",
          });
        }
      });
    },
    getDeviceList(a = -1) {
      getDeviceByArea({ params: [a, -1] }).then((res) => {
        //“params”: [ 0 , 0] 所有设备用用-1，未分区0, 其余用上面的分区序号，第二项是分页页面
        if (res.result && res.result.length > 0) {
          this.devicelist = res.result[0].map((el) => {
            el[5] = false;
            return el;
          })
          this.devicelistAll = res.result[0];
          this.total = res.result[1];
          this.pageSize = res.result[2];
        } else {
          this.devicelist = res.result;
        }
      });

      getAreaDegree({ params: [] }).then((res) => {
        if (res.result && res.result.length > 0) {
          this.areaDegree = res.result;
        }
      });
    },
    searchArea(a = 0) {
      let treeData = JSON.parse(JSON.stringify(this.$refs.areaTree.getCheckedNodes()));
      if (!treeData || treeData.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择分区再查询！",
          customClass: "mzindex",
        });
        return false;
      }
      getDeviceByArea({
        params: [
          treeData.map((m) => {
            return m.index;
          })[0],
          -1,
        ],
      }).then((res) => {
        //“params”: [ 0 , 0] 所有设备用用-1，未分区0, 其余用上面的分区序号，第二项是分页页面
        if (!res.error) {
          this.devicelist = res.result[0];
          this.total = res.result[1];
          this.pageSize = res.result[2];
        }
      });
    },
    currentChange(val) {
      this.pageNum = val - 1;
      this.searchArea();
    },
    // 单选操作
    handleCheckChange(data, checked) {
      let checkDevice = this.$refs.shebeiList.checkDevice;
      if (checked) {
        this.checkedId = data.index;
        this.$refs.areaTree.setCheckedKeys([data.index]);
        getDeviceByArea({ params: [data.index, -1] }).then((res) => {
          //“params”: [ 0 , 0] 所有设备用用-1，未分区0, 其余用上面的分区序号，第二项是分页页面
          if (!res.error) {
            this.devicelist = res.result[0].map((r) => {
              if (checkDevice.indexOf(r[1]) > -1) {
                r[4] = true;
              } else {
                r[4] = false;
              }
              return r;
            });
            this.total = res.result[1];
          }
        });
      } else {
        if (this.checkedId === data.index) {
          this.$refs.areaTree.setCheckedKeys([data.index]);
        }
      }
    },

    checkBtn() {
      let checkRow = JSON.parse(JSON.stringify(this.$refs.table.getSelectionRows()));
      tempTasksPlay({ params: [checkRow[0][0]] }).then((res) => {
        if (!res.error) {
          if (res.result.length > 0) {
            this.renwuData = res.result;
          } else {
            ElMessage({
              type: "info",
              message: "暂无歌曲",
              customClass: "mzindex",
            });
          }
        }
      });
    },
  },
  created() { 
    if(window.location.protocol == 'https' || window.location.protocol == 'https:'){
      this.wsradio = `wss://${window.location.host}/rtp/broadcast`;
    }else{
      this.wsradio = `ws://${window.location.host}/rtp/broadcast`;
    }
    this.getDeviceList();

    if (window.sessionStorage.getItem("userRole")) {
      this.permissions = JSON.parse(window.sessionStorage.getItem("userRole"));
    }
  },
  mounted() {},
  unmounted() {
    // this.websocketCloseBtn;
    this.recorder.endstop();
    this.recorder.stopWebsocket();
    this.loadBtn = false;
  },
};
</script>
<style lang="scss" scoped>
.button-box {
  & div {
    padding-left: 35px;
    margin: 28px 0;
    border-right: 1px solid #f5f5f5;
  }
}
.titleTop {
  margin: 10px 0;

  & i {
    width: 3px;
    height: 16px;
    background: #409eff;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }
}
.songList {
  width: 100%;
  height: calc(50vh - 100px);
  position: relative;
}
.operation {
  & > div:not(:last-child) {
    width: 50px;
    height: 50px;
    font-size: 13px;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  & > div:last-child {
    width: 100px;
    height: 50px;
    font-size: 13px;
    float: left;
    cursor: pointer;
  }
}
.yinliang {
  & > span {
    width: 26px;
    height: 26px;
    display: block;
    border-radius: 50px;
    border: 1px solid #000;
    float: left;
    text-align: center;
    padding-top: 1px;
    box-sizing: border-box;
  }
}
/deep/ .el-table__header .el-checkbox {
  display: none;
}

.jindu {
  width: 50px;
  height: 8px;
  border-radius: 8px;
  background: #ddd;
  display: inline-block;
  overflow: hidden;

  & i {
    height: 8px;
    display: inline-block;
    background: lightgreen;
    float: left;
  }
}

.loadBtn {
  width: 110px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 !important;
  padding-left: 0 !important;
  background: rgba(255, 255, 255, 0.95);
  margin-left: 35px !important;
}

.radio-img {
  width: 100%;
  height: 60px;
  text-align: center;
  padding-left: 0 !important;
  margin: 0 !important;
  .radioCanvs {
    width: 41px;
    height: 60px;
  }
}
::v-deep .el-tree-node__content {
  height: 70px;
  line-height: 70px;
}

::v-deep .el-tree-node.is-current > .el-tree-node__content {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #fff !important;
}
::v-deep .is-checked {
  background-color: #fff !important;
}
.custom-tree-node {
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}
</style>
