<template>
  <div class="home warpper-content-box" style="background: transparent">
    <el-row style="width: 90%; margin: 0px 5%; padding-top: 20px">
      <el-col :span="4">
        <div style="margin: 20px 50px">
          <el-button style="color: #999" type="text" @click="userDialog = true">
            <img
              width="18"
              style="margin-right: 6px"
              src="../assets/image/user.png"
              alt=""
              srcset=""
            />
            创建账户
          </el-button>
        </div>
        <div style="margin: 20px 50px">
          <el-button style="color: #999" type="text" @click="editUser()">
            <svg-icon size="22" style="margin-right: 3px" name="page2Icon2"></svg-icon
            >编辑账户
          </el-button>
        </div>
        <div style="margin: 20px 50px">
          <el-button style="color: #999" type="text" @click="delUser()">
            <svg-icon size="18" style="margin-right: 4px" name="page2Icon3"></svg-icon
            >删除账户
          </el-button>
        </div>
      </el-col>
      <el-col :span="20">
        <div style="background: #fff">
          <el-table
            :data="userData"
            ref="table"
            @select="dialogCheck"
            style="width: 100%; margin-top: 20px"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column :label="item" v-for="(item, index) in userHead" :key="index">
              <template v-if="index == 3" #default="scope">
                <span v-if="scope.row[3].length > 0">
                  {{
                    scope.row[3]
                      .map((m) =>
                        m == 0 ? "文件上传" : m == 1 ? "文件审核" : "语音播报"
                      )
                      .join("，")
                  }}
                </span>
                <span v-else>暂无权限</span>
              </template>
              <template v-else-if #default="scope">
                {{ scope.row[index] }}
              </template>
            </el-table-column>
          </el-table>

          <ElPagination
            style="margin-top: 20px"
            :total="totalNum"
            :current-page="pageNum + 1"
            :page-count="pageSize"
            @current-change="currentChange"
          ></ElPagination>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      title="新增账号"
      :before-close="handleClose"
      v-model="userDialog"
      width="800px"
    >
      <el-form ref="formData" :model="formData" label-width="80px">
        <el-form-item label="账号名称">
          <el-input v-model="formData[0]" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="formData[1]" />
        </el-form-item>
        <el-form-item label="优先级">
          <!-- <el-input type="number" :min="0" :max="10" v-model="formData.priority" /> -->
          <el-input-number
            style="width: 100%"
            v-model="formData[2]"
            :min="0"
            :max="10"
            controls-position="right"
          />
        </el-form-item>
        <el-form-item label="权限">
          <el-checkbox-group v-model="formData[3]">
            <el-checkbox :label="0" name="type">文件上传</el-checkbox>
            <el-checkbox :label="1" name="type">文件审核</el-checkbox>
            <el-checkbox :label="2" name="type">语音播报</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="设备权限">
          <chooseEquipment ref="equipment"></chooseEquipment>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" type="">取消</el-button>
        <el-button @click="submitUserData" type="primary">提交</el-button>
      </template>
    </el-dialog>

    <el-dialog
      title="编辑账号"
      :before-close="handleClose2"
      v-model="userDialog2"
      width="800px"
    >
      <el-form ref="formData" :model="formData2" label-width="80px">
        <el-form-item label="账号名称">
          <el-input disabled v-model="formData2[0]" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="formData2[1]" />
        </el-form-item>
        <el-form-item label="优先级">
          <el-input-number
            style="width: 100%"
            v-model="formData2[2]"
            :min="0"
            :max="10"
            controls-position="right"
          />
        </el-form-item>
        <el-form-item label="权限">
          <el-checkbox-group v-model="formData2[3]">
            <el-checkbox :label="0" name="type">文件上传</el-checkbox>
            <el-checkbox :label="1" name="type">文件审核</el-checkbox>
            <el-checkbox :label="2" name="type">语音播报</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="设备权限">
          <chooseEquipment :echoData="formData2[4]" ref="equipment2"></chooseEquipment>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose2" type="">取消</el-button>
        <el-button @click="submitUserData2" type="primary">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { loginuserDel, loginuserAdd, loginuserList, loginuserMod } from "../utils/api";
import chooseEquipment from "../components/chooseEquipment.vue";
import { ElMessageBox, ElMessage } from "element-plus/lib/components";
export default {
  components: { chooseEquipment },
  props: {},
  data() {
    return {
      pageSize: 10,
      userHead: [
        // "状态",
        "登录账户",
        "密码",
        "账户优先级",
        "权限",
        "创建时间",
        "到期时间",
      ],
      totalNum: 0,
      pageNum: 0,
      userData: [],
      userDialog: false,
      userDialog2: false,
      formData: ["", "", 1, [0, 1, 2], []],
      formData2: ["", "", 1, [0, 1, 2], []],
    };
  },
  watch: {},
  computed: {},
  methods: {
    dialogCheck(selection, row) {
      this.$refs.table.clearSelection();
      this.selectioned = {}; //这里清除选择的记忆；主要是避免取消掉原来选中的还残留原来选中的东西
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return;
      }
      if (row) {
        this.selectioned = row;
        this.$refs.table.toggleRowSelection(row, true);
      }
    },
    currentChange(val) {
      this.pageNum = val - 1;
      this.getUserList();
    },
    handleClose() {
      this.formData = ["", "", 1, [], []];
      this.userDialog = false;
    },
    handleClose2() {
      this.formData2 = ["", "", 1, [], []];
      this.userDialog2 = false;
    },
    submitUserData() {
      let limits = [];
      console.log(this.$refs.equipment.devicelist);
      limits = this.$refs.equipment.devicelist
        .filter((f) => {
          return f[6] === true;
        })
        .map((m) => {
          return m[1];
        });

      if (this.formData[0] == "" || this.formData[1] == "") {
        ElMessage({
          type: "error",
          message: "请先输入账号和密码！",
          customClass: "mzindex",
        });

        return false;
      }
      if (!limits || limits.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });
        return false;
      }
      this.formData[4] = limits;
      loginuserAdd({ params: this.formData }).then((res) => {
        if (!res.error) {
          this.userDialog = false;
          ElMessage({
            message: "新增成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getUserList();
          this.formData = ["", "", 1, [], []];
        }
      });
    },
    submitUserData2() {
      let limits = [];
      limits = this.$refs.equipment2.devicelist
        .filter((f) => {
          return f[6] === true;
        })
        .map((m) => {
          return m[1];
        });
      if (this.formData2[0] == "" || this.formData2[1] == "") {
        ElMessage({
          type: "error",
          message: "请先输入账号和密码！",
          customClass: "mzindex",
        });
        return false;
      }
      if (!limits || limits.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择设备！",
          customClass: "mzindex",
        });

        return false;
      }
      this.formData2[4] = limits;
      loginuserMod({ params: this.formData2 }).then((res) => {
        if (!res.error) {
          this.userDialog2 = false;
          ElMessage({
            message: "编辑成功！",
            type: "success",
            customClass: "mzindex",
          });
          this.getUserList();
          this.formData2 = ["", "", 1, [], []];
        }
      });
    },
    getUserList() {
      loginuserList({ params: [this.pageNum] }).then((res) => {
        if (!res.error) {
          this.userData = res.result[0];
          this.totalNum = res.result[1];
          this.pageSize = res.result[2];
        }
      });
    },

    editUser() {
      let item2 = JSON.parse(JSON.stringify(this.$refs.table.getSelectionRows()))[0];
      if (!item2 || item2.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      let item3 = [];
      item3[0] = item2[0];
      item3[1] = item2[1];
      item3[2] = item2[2];
      item3[3] = item2[3];
      item3[4] = item2[6];
      this.formData2 = [...item3];
      this.userDialog2 = true;
    },
    delUser() {
      let item = JSON.parse(JSON.stringify(this.$refs.table.getSelectionRows()))[0];
      if (!item || item.length == 0) {
        ElMessage({
          type: "error",
          message: "请先选择任务",
          customClass: "mzindex",
        });
        return false;
      }
      ElMessageBox.confirm("是否删除当前账号！", "提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          loginuserDel({ params: [JSON.parse(JSON.stringify(item))[0]] }).then((res) => {
            if (!res.error) {
              ElMessage({
                type: "success",
                message: "删除成功！",
                customClass: "mzindex",
              });
              this.getUserList();
            }
          });
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getUserList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table__header .el-checkbox {
  display: none;
}
/deep/ .el-table th {
  background: #3d7fff;
  color: #fff;
}
/deep/ .el-table {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  & td {
    background: #eff2f9;
  }
}
</style>
