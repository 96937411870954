<template>
  <div>
    <el-button
      style="margin: 10px 0 0px 16px"
      @click="allCheck"
      v-if="isCheck"
      type="text"
      >全选/取消</el-button
    >
    <el-button @click="searchBtn" style="float: right; margin: 10px 10px 0 0"
      >搜索</el-button
    >
    <el-input
      style="width: 160px; float: right; margin: 10px 10px 0 0"
      v-model="keyword"
      placeholder="请输入搜索关键字"
    />
  </div>
  <ul class="audio-box" v-if="equipment.length > 0">
    <li
      class="audio-list"
      v-for="(item, index) in equipment"
      :key="index"
      @click="changeChe(index)"
    >
      <div class="audio-top">
        <img
          style="width: 14px; float: left; height: 14px"
          v-if="isCheck"
          :src="item[5] ? checked2 : checkout2"
          alt=""
          srcset=""
        />
        
        <div style="width: 50%" :class="isCheck ? 'floatRight' : ''">
          <template v-if="item[0]"> <i class="green-icon"></i> 在线 </template>
          <template v-else> <i class="red-icon"></i> 离线 </template>
        </div>
      </div>
      
      <div class="centerBox">
        <div :class="item[5] == true ? 'bluebg' : ''">
          <!-- <svg-icon name="yinxiang" size="30" color="#fff" /> -->
          <img width="26" src="../assets/image/laba.png" alt="" srcset="" />
        </div>
      </div>
      <p style="text-align:center">{{ item[4] }}</p>
      <div class="fontFoot">
        <p class="line2">{{ item[2] ? item[2] : item[1] }}</p>
        <p>音量：{{ item[3] }}</p>
      </div>
    </li>
  </ul>
  <el-empty v-else description="暂无设备数据！" />
</template>

<script>
import { deviceStatusUpdate } from "../utils/api";
export default {
  components: {},
  props: {
    isCheck: {
      type: Boolean,
      default: false,
    },
    equipment: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      keyword: "",
      checked2: require("../assets/image/checked2.png"),
      checkout2: require("../assets/image/checkout2.png"),
      chechType: true,
      setIn: null,
      checkDevice: [],
    };
  },
  watch: {
    equipment: {
      hander(newV, oldV) {
        this.equipment = newV;
      },
      deep: true,
    },
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  methods: {
    searchBtn() {
      this.$emit("searchKey", this.keyword);
    },
    changeChe(item) {
      if (this.equipment[item][5] == true) {
        this.equipment[item][5] = false;
      } else {
        this.equipment[item][5] = true;
      }
      let cherow = JSON.parse(JSON.stringify(this.equipment[item]));
      if (this.checkDevice.filter((f) => cherow[1] === f).length > 0) {
        this.checkDevice = this.checkDevice.filter((f) => cherow[1] != f);
      } else {
        this.checkDevice.push(cherow[1]);
      }
      this.$emit("changeCheck", true);
    },
    allCheck() {
      this.equipment.map((v, index) => {
        if (this.chechType) {
          v[5] = true;
          // this.$set(this.equipment, [index], !v.checked);
        } else {
          v[5] = false;
        }
      });
      this.chechType = !this.chechType;
    },
  },
  created() {},
  mounted() {
    let that = this;
    this.setIn = setInterval(function () {
      deviceStatusUpdate({
        params: [...that.equipment.map((m) => m[1])],
      }).then((res) => {
        console.log(that.equipment);
        that.equipment.map((m) => {
          Object.keys(res.result[0]).map((m1) => {
            if (m1 == m[1]) {
              m[0] = res.result[0][m1]['status'];
              m[3] = res.result[0][m1]['vol'];
              m[4] = res.result[0][m1]['state'];
            }
          });
        });
        that.$forceUpdate();
      });
    }, 10000);
  },
  unmounted() {
    clearInterval(this.setIn);
  },
};
</script>
<style lang="scss" scoped>
.audio-box {
  width: 100%;
  margin: 0 16px;
  font-size: 13px;
  font-weight: 500;
  height: calc(100vh - 230px);
  overflow-y: auto;
  & li {
    border: 1px solid #c8ccd5;
    border-radius: 3px;
    height: 70px;
    padding:6px 10px;
    box-sizing: border-box;
    margin: 10px 0 0 0;
  }
  & .audio-list {
    margin: 5px;
  }
  & .audio-list {
    width: 110px;
    height: 158px;
    float: left;
  }
}
.green-icon {
  width: 10px;
  height: 10px;
  background: rgb(0, 255, 0);
  display: inline-block;
  border-radius: 10px;
}
.red-icon {
  width: 10px;
  height: 10px;
  background: red;
  display: inline-block;
  border-radius: 10px;
}
.centerBox {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #aaa;
    border-radius: 50%;
  }
}

.fontFoot {
  & > p:nth-child(1) {
    text-align: center;
    height:25px;
  }
  & > p:nth-child(2) {
    margin-top: 6px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    background: #9199a8;
    color: #fff;
    border-radius: 20px;
  }
}
.floatRight {
  float: right;
}
.bluebg {
  background: #458af6 !important;
}
</style>
